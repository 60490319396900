import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'components/common/modal'
import { DataPicker } from 'components/common/data-picker'
import { useBaseInfo, useScoutMyRequest } from 'apis/hooks'
import { BoldText, MediumText } from 'components/common/text'
import { Column } from 'components/common/layout'
import { WithIdNameParentId } from 'types/common'
import { putRequestScoutRequestMe } from 'apis/request'
import { CategorySelector } from 'components/misc/selector/category-selector'
import { requestToast } from 'components/common/toast'
import { mutate } from 'swr'
import { useStores } from 'stores/globals'

const DEPLOY_TIME = '2024-09-11T16:13:00Z'

export const LocationSelectorModal: React.FC = () => {
  const { loginStore } = useStores()
  const { data: baseInfo } = useBaseInfo()
  const { data: myProfile } = useScoutMyRequest()
  const [isOpen, setIsOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedLocations, setSelectedLocations] = useState<
    WithIdNameParentId[]
  >([])
  const locations = useMemo(
    () => baseInfo?.locations.filter((item) => !item.isRemote) || [],
    [baseInfo?.locations],
  )
  useEffect(() => {
    setSelectedLocations(
      baseInfo?.locations.filter((item) =>
        myProfile?.locations.map((item) => item.id).includes(item.id),
      ) || [],
    )
  }, [baseInfo, myProfile])

  if (!myProfile || !baseInfo || !isOpen) return null

  const isScoutRequestCreatedAfterReleaseDate =
    new Date(myProfile.createdAt).getTime() > new Date(DEPLOY_TIME).getTime()
  const isScoutRequestUpdatedAfterReleaseDate =
    new Date(myProfile.updatedByUserAt).getTime() >
    new Date(DEPLOY_TIME).getTime()
  const isFullRemoteNecessary =
    myProfile.miscOptions.remoteWorkPreference === 'FULL_REMOTE_NECESSARY'
  if (
    loginStore.isFake ||
    isFullRemoteNecessary ||
    isScoutRequestCreatedAfterReleaseDate ||
    isScoutRequestUpdatedAfterReleaseDate
  )
    return null

  const save = async () => {
    if (isLoading) return
    try {
      setIsLoading(true)
      if (selectedLocations.length === 0) {
        throw '최소 한개의 지역을 선택해주세요'
      }
      const selectedLocationIds = selectedLocations.map((item) => item.id)
      await requestToast(
        putRequestScoutRequestMe({
          locations: selectedLocationIds,
        }),
        {
          loading: '저장중',
          success: '근무 가능 지역이 내 프로필에 저장되었습니다.',
        },
      )
      await mutate('/scouts/requests/me')
      setIsOpen(false)
    } catch (e) {
      alert(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal width={500} disableCloseBtn disableBackgroundClick>
      <DataPicker
        data={locations}
        selectedData={selectedLocations}
        onClose={save}
        onDelete={(item) => {
          if (!item.parentId) {
            setSelectedLocations((locations) =>
              locations.filter((location) => location.parentId !== item.id),
            )
          } else {
            setSelectedLocations((locations) =>
              locations.filter((location) => location.id !== item.id),
            )
          }
        }}
        onReset={() => setSelectedLocations([])}
      >
        <Column style={{ gap: 40 }}>
          <Column style={{ gap: 8 }}>
            <MainText>근무 가능 지역 정보 기능 업데이트</MainText>
            <SubText>{`새로운 지역 설정 기능으로\n딱 맞는 스카우트 제안을 받아볼 수 있어요`}</SubText>
          </Column>
          <CategorySelector
            data={locations}
            selectedData={selectedLocations}
            onChange={(locations) => setSelectedLocations(locations)}
            isFilter
          />
        </Column>
      </DataPicker>
    </Modal>
  )
}

const MainText = styled(BoldText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h4};
  }
  font-size: ${(p) => p.theme.fontSize.h3};
`

const SubText = styled(MediumText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    line-height: 22px;
    font-size: ${(p) => p.theme.fontSize.desc};
  }
  line-height: 26px;
  color: ${(p) => p.theme.color.gray1};
  font-size: ${(p) => p.theme.fontSize.h5};
`

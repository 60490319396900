import React from 'react'
import Image from 'next/legacy/image'
import { BaseText, MediumText } from 'components/common/text'
import moment from 'moment/moment'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { EarthIcon, SproutIcon } from 'components/common/icon'

const ICON_SIZE = 18

// 스타트업 투자정보 테이블 컨테이너
export const StartupTableInfo: React.FC<{
  data: {
    fundingRound: string
    recentFundingDate: string
    accumulatedFunding: number
    memberCount: number
    devCount: number
    hasCto: boolean
    serviceAreas?: string[]
  }
}> = ({ data }) => {
  return (
    <Table>
      <TableRow>
        <RowName>
          <EarthIcon size={ICON_SIZE} />
          <MediumText>서비스 분야</MediumText>
        </RowName>
        <DataValue>
          {data.serviceAreas && data.serviceAreas.length > 0
            ? data.serviceAreas.join(' ‧ ')
            : '-'}
        </DataValue>
      </TableRow>
      <TableRow>
        <RowName>
          <SproutIcon size={ICON_SIZE} />
          <MediumText>투자 단계</MediumText>
        </RowName>
        <DataValue>
          {data.fundingRound ? data.fundingRound : '-'}
          {data.recentFundingDate &&
            ` (${moment(data.recentFundingDate).format('YYYY.MM.')})`}
        </DataValue>
      </TableRow>
      <TableRow>
        <RowName>
          <Image
            src='/finance.svg'
            alt='finance'
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
          <MediumText>누적 투자 유치액</MediumText>
        </RowName>
        <DataValue>
          {data.accumulatedFunding ? `${data.accumulatedFunding}억` : '미공개'}
        </DataValue>
      </TableRow>
      <TableRow>
        <RowName>
          <Image
            src='/account-group-outline.svg'
            alt='account-group-outline'
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
          <MediumText>전체 / 개발자</MediumText>
        </RowName>
        <DataValue>
          {data.memberCount ? `${data.memberCount}명` : '-'}
          {' / '}
          {data.devCount ? `${data.devCount}명` : '-'}
          {data.hasCto ? '(CTO 있음)' : ''}
        </DataValue>
      </TableRow>
    </Table>
  )
}

const Table = styled(Column)`
  width: 100%;
  border: 1px solid ${(p) => p.theme.color.line};
  padding: 0 20px;
  border-radius: 8px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 0 16px;
  }
`

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr;
  gap: 16px;
  padding: 15px 0;
  :not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.color.gray3};
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    grid-template-columns: 130px 1fr;
  }
`

const RowName = styled(Row)`
  gap: 5px;
`

const DataValue = styled(BaseText)`
  color: ${(p) => p.theme.color.black2};
`

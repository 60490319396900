import React from 'react'
import styled from 'styled-components'
import { Column } from 'components/common/layout'
import { BaseText, BoldText } from 'components/common/text'
import { TagContainer, Tags } from 'components/common/tag'
import { WithIdName } from 'types/common'
import { CHECK_HTML } from 'infra/constants'
import { TextViewer } from 'components/common/text-editor'
import { Hashtag } from 'components/common/hashtag'
import { Map } from 'components/startup/startup-map'

const convertLi = (li: string) => {
  const array = li?.split('\n').filter((item) => item !== '')
  const result = []
  for (let i = 0; i < array.length; i++) {
    const item = array[i]
    if (item.startsWith('-')) {
      const lastEle = result.at(-1)
      const subLi = item.slice(1).trim()
      if (Array.isArray(lastEle)) {
        lastEle.push(subLi)
      } else {
        result.push([subLi])
      }
    } else result.push(item)
  }
  return result
}

const ListItem: React.FC<{ content: string }> = ({ content }) => (
  <li>
    <ListText>{content}</ListText>
  </li>
)

const List: React.FC<{ data: string }> = ({ data }) => {
  return CHECK_HTML.test(data) ? (
    <TextViewer value={data} />
  ) : (
    <ul>
      {convertLi(data)?.map((item, index) =>
        typeof item === 'string' ? (
          <ListItem key={index} content={item} />
        ) : (
          <SubUl key={index}>
            {item.map((li, index) => (
              <ListItem key={index} content={li} />
            ))}
          </SubUl>
        ),
      )}
    </ul>
  )
}

export const PositionDetail: React.FC<{
  data: {
    task: string
    qualification: string
    preferred: string
    hiringProcess: string
    expectationKeywords: WithIdName[]
    techStacks: string[]
    location: string
    address: string
  }
}> = ({ data }) => {
  const {
    task,
    qualification,
    preferred,
    hiringProcess,
    expectationKeywords,
    techStacks,
    location,
    address,
  } = data
  return (
    <Column style={{ gap: 40 }}>
      {techStacks.length > 0 && (
        <Column>
          <InfoTitle>스킬</InfoTitle>
          <TagContainer>
            <Tags data={techStacks} />
          </TagContainer>
        </Column>
      )}
      {task && (
        <Column>
          <InfoTitle>주요업무</InfoTitle>
          <List data={task} />
        </Column>
      )}
      {qualification && (
        <Column>
          <InfoTitle>자격요건</InfoTitle>
          <List data={qualification} />
        </Column>
      )}
      {preferred && (
        <Column>
          <InfoTitle>우대사항</InfoTitle>
          <List data={preferred} />
        </Column>
      )}
      {hiringProcess && (
        <Column>
          <InfoTitle>채용절차</InfoTitle>
          <List data={hiringProcess} />
        </Column>
      )}
      {expectationKeywords && (
        <Column>
          <InfoTitle>합류하면 얻을 수 있는 것</InfoTitle>
          <Hashtag
            data={expectationKeywords.map((x) => x.name.replaceAll(' ', '_'))}
          />
        </Column>
      )}
      <Column>
        <InfoTitle>근무지</InfoTitle>
        <Column style={{ gap: 16 }}>
          <BaseText>
            {address} ({location.split(' ').join(', ')})
          </BaseText>
          <Map
            mapId='position-map'
            width={600}
            height={600 * (9 / 16)}
            address={address}
          />
        </Column>
      </Column>
    </Column>
  )
}

const InfoTitle = styled(BoldText)`
  margin: 20px 0;
  font-size: ${(p) => p.theme.fontSize.h5};
  @media ${(p) => p.theme.deviceSize.mobile} {
    margin: 10px 0;
  }
`

const ListText = styled(BaseText)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
`

const SubUl = styled.ul`
  padding-left: 25px;
`

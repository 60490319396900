import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Image from 'next/legacy/image'
import { Column, Row } from 'components/common/layout'
import { SemiBoldText, SmallText } from 'components/common/text'
import { BtnSize, BtnType, Button } from 'components/common/button'
import { getAppInstallPromptPopUpStorage } from 'utils/storages'
import { HIDE_APP_INSTALL_PROMPT_POP_UP, IS_APP } from 'infra/constants'
import { useIsClient } from 'usehooks-ts'
import { useIsDesktop } from 'utils/hooks'

const IOS_APP_STORE_URL =
  'https://apps.apple.com/kr/app/%EA%B7%B8%EB%A3%B9%EB%B0%94%EC%9D%B4-%EC%8A%A4%ED%83%80%ED%8A%B8%EC%97%85-%EC%B7%A8%EC%97%85-%EC%9D%B4%EC%A7%81-%EC%B1%84%EC%9A%A9%ED%94%8C%EB%9E%AB%ED%8F%BC/id6639606707'
const ANDROID_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=prod.groupby.app'

const IOS_APP_SCHEME = 'groupbyapp://'
const ANDROID_APP_SCHEME =
  'intent://#Intent;package=prod.groupby.app;scheme=groupbyapp;end'

export const AppInstallPromptPopUp: React.FC = () => {
  const isClient = useIsClient()
  const isDesktop = useIsDesktop()
  const [isHidePopUp, setIsHidePopUp] = useState(true)

  useEffect(() => {
    const isHide = getAppInstallPromptPopUpStorage()
    setIsHidePopUp(isHide || isDesktop || IS_APP)
  }, [isDesktop])

  if (!isClient || isHidePopUp) return null

  const handleAppRedirect = () => {
    if (/iPhone/i.test(navigator.userAgent)) {
      window.location.href = IOS_APP_SCHEME
      setTimeout(() => {
        window.location.href = IOS_APP_STORE_URL
      }, 2000)
    } else if (/android/i.test(navigator.userAgent)) {
      window.location.href = ANDROID_APP_SCHEME
      setTimeout(() => {
        window.location.href = ANDROID_PLAY_STORE_URL
      }, 2000)
    }
  }

  const closePopUp = () => {
    localStorage.setItem(HIDE_APP_INSTALL_PROMPT_POP_UP, 'true')
    setIsHidePopUp(true)
  }

  return (
    <Container>
      <Row style={{ gap: 12 }}>
        <Image src='/logo.svg' alt='logo' width={44} height={44} />
        <Column style={{ gap: 4 }}>
          <MainText>그룹바이 앱</MainText>
          <SmallText>더 빠르고 간편하게 사용해보세요.</SmallText>
        </Column>
      </Row>
      <Button
        size={BtnSize.MEDIUM}
        variant={BtnType.WHITE}
        style={{ width: 253 }}
        onClick={() => {
          handleAppRedirect()
          closePopUp()
        }}
      >
        그룹바이 앱으로 보기
      </Button>
      <LinkText onClick={closePopUp}>괜찮아요, 모바일웹으로 볼게요.</LinkText>
    </Container>
  )
}

const Container = styled(Column)`
  z-index: 9999;
  position: fixed;
  gap: 24px;
  width: 100%;
  bottom: -1px;
  padding: 24px;
  align-items: center;
  border-radius: 16px 16px 0 0;
  border-top: 1px solid ${(p) => p.theme.color.line};
  background-color: ${(p) => p.theme.color.black1};
  span {
    color: ${(p) => p.theme.color.white1};
  }
`

const MainText = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.h5};
`

const LinkText = styled(SmallText)`
  text-decoration: underline;
  cursor: pointer;
`

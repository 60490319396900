import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BaseText, MediumText, SemiBoldText } from 'components/common/text'
import { Card } from 'components/common/card'
import { Modal } from 'components/common/modal'
import { Column } from 'components/common/layout'
import { Button } from 'components/common/button'
import { useRouter } from 'next/router'
import { useDashboardPositions, useDashboardStartupDetail } from 'apis/hooks'
import {
  DEFAULT_MAX_SALARY,
  HIDE_DASHBOARD_UPDATED_POSITION_NOTICE_KEY,
  MILLISECONDS_PER_DAY,
} from 'infra/constants'
import { getDashboardUpdatedPositionNotice } from 'utils/storages'

export const DashboardUpdatedPositionNoticeModal: React.FC = () => {
  const router = useRouter()
  const { data: positions } = useDashboardPositions()
  const { data: startup } = useDashboardStartupDetail()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const storage = getDashboardUpdatedPositionNotice()
    const isExpire = !storage || storage.expire < Date.now()
    if (!isExpire) return
    const shouldShowNotice = positions?.some((item) => {
      const isPublished = item.isPublished
      const isIncomplete =
        !item.address ||
        !item.location ||
        !item.remoteWorkPreference ||
        !item.stockOption ||
        (item.minDesiredSalary === 0 &&
          (item.maxDesiredSalary === 0 ||
            item.maxDesiredSalary === DEFAULT_MAX_SALARY))
      return isPublished && isIncomplete
    })
    if (!shouldShowNotice) return
    setIsOpen(true)
  }, [positions])

  if (!isOpen || !positions || !startup) return null

  const handleDismiss = () => {
    localStorage.setItem(
      HIDE_DASHBOARD_UPDATED_POSITION_NOTICE_KEY,
      JSON.stringify({
        expire: Date.now() + MILLISECONDS_PER_DAY,
      }),
    )
    setIsOpen(false)
  }

  return (
    <Modal width={400} disableCloseBtn>
      <Container>
        <MainText>
          더욱 적합한 지원자를 만나기 위해,
          <br />
          채용 공고를 업데이트해주세요
        </MainText>
        <Column style={{ gap: 16 }}>
          <Column style={{ gap: 8 }}>
            <BoxTitleText>신규 추가된 채용 공고 정보</BoxTitleText>
            <Box as='ul'>
              <BaseText as='li'>근무지 (원격 근무 여부)</BaseText>
              <BaseText as='li'>예상 연봉</BaseText>
              <BaseText as='li'>스톡옵션 제공 여부</BaseText>
              <BaseText as='li'>경력 채용 시 팀장급, C-Level 희망</BaseText>
              <BaseText as='li'>
                인턴 채용 시 계약 기간, 정규직 전환 여부
              </BaseText>
            </Box>
          </Column>
          <AlertText>
            * 정보가 없으면 인재가 채용 공고를 탐색할 때<br />
            {startup?.name}의 채용 공고 노출 순위가 낮아져요
          </AlertText>
        </Column>
        <Column style={{ gap: 24 }}>
          <Button
            onClick={() => {
              router.push('/dashboard/positions')
              handleDismiss()
            }}
          >
            채용 공고 업데이트하러 가기
          </Button>
          <BtnText onClick={handleDismiss}>하루동안 보지 않기</BtnText>
        </Column>
      </Container>
    </Modal>
  )
}

const Container = styled(Column)`
  gap: 40px;
  padding: 32px;
`

const MainText = styled(SemiBoldText)`
  text-align: center;
  font-size: ${(p) => p.theme.fontSize.h4};
  line-height: 1.4;
`

const BoxTitleText = styled(SemiBoldText)`
  font-size: 14px;
  text-align: center;
  color: ${(p) => p.theme.color.gray1};
`

const AlertText = styled(MediumText)`
  text-align: center;
  color: ${(p) => p.theme.color.alert};
  font-size: ${(p) => p.theme.fontSize.small};
  line-height: 1.4;
`

const Box = styled(Card)`
  align-self: center;
  width: fit-content;
  gap: 8px;
  padding: 12px;
  padding-left: 32px;
  border-radius: 12px;
  background-color: ${(p) => p.theme.color.background2};
`

const BtnText = styled(BaseText)`
  text-align: center;
  color: ${(p) => p.theme.color.gray1};
  text-decoration: underline;
  cursor: pointer;
`

import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Column } from 'components/common/layout'
import { BaseText } from 'components/common/text'
import { SectionTitle } from 'components/startup/startup-styles'
import { NotFoundContent } from 'components/startup/startup-styles'
import { observer } from 'mobx-react'
import { useIsDesktop } from 'utils/hooks'
import { useWindowSize } from 'usehooks-ts'
import { KAKAO_REST_API_KEY, NAVER_MAP_CLIENT_ID } from 'infra/constants'
import Script from 'next/script'

export const Map: React.FC<{
  mapId: string
  address: string
  width: number
  height: number
}> = observer(({ mapId, address, width, height }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapRef = useRef<any>()
  const isDesktop = useIsDesktop()
  const { width: useWidth } = useWindowSize()

  const loadMap = () => {
    // 주소에서 숫자뒤에 F가 붙는 텍스트를 n층 으로 변환
    const normalizedAddress = address.replace(/(\d+)F\b/g, '$1층')

    fetch(
      `https://dapi.kakao.com/v2/local/search/address?query=${normalizedAddress}`,
      {
        method: 'GET',
        headers: {
          Authorization: `KakaoAK ${KAKAO_REST_API_KEY}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (!res.documents || res.documents.length <= 0) return
        const location = new naver.maps.LatLng(
          res.documents[0].y,
          res.documents[0].x,
        )
        //지도 그리기
        const map = (mapRef.current = new naver.maps.Map(mapId, {
          center: location,
          zoomControl: true, // 줌 설정
          zoom: 15,
          zoomControlOptions: {
            style: naver.maps.ZoomControlStyle.SMALL,
            position: naver.maps.Position.TOP_RIGHT,
          },
        }))
        //마커 설정
        mapRef.current = new naver.maps.Marker({
          map,
          position: location, //마커 좌표
        })
      })
  }

  useEffect(() => {
    if (typeof naver === 'undefined') {
      const interval = setInterval(() => {
        if (typeof naver !== 'undefined') {
          clearInterval(interval)
          loadMap()
        }
      }, 100)
      return () => clearInterval(interval)
    } else {
      loadMap()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  return (
    <>
      <Script
        src={`https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${NAVER_MAP_CLIENT_ID}`}
      />
      <Main
        id={mapId}
        ref={mapRef}
        width={width}
        height={isDesktop ? height : useWidth}
      />
    </>
  )
})

export const StartupMap: React.FC<{
  width: number
  height: number
  address: string
}> = ({ width, height, address }) => {
  return (
    <Column id='location'>
      <SectionTitle>위치</SectionTitle>
      <Column style={{ gap: 16 }}>
        {address ? (
          <>
            <ContentText>{address}</ContentText>
            <Map
              mapId='startup-map'
              width={width}
              height={height}
              address={address}
            />
          </>
        ) : (
          <NotFoundContent>위치 준비중입니다.</NotFoundContent>
        )}
      </Column>
    </Column>
  )
}

const Main = styled.div<{ width: number; height: number }>`
  @media ${(p) => p.theme.deviceSize.desktop} {
    width: ${(p) => p.width}px;
    height: ${(p) => p.height}px;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    height: ${(p) => p.height / 1.8}px;
  }
  z-index: 1;
`

const ContentText = styled(BaseText)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
`

import {
  DEFAULT_MAX_EXPERIENCE,
  DEFAULT_MAX_MEMBER_COUNT,
  DEFAULT_MIN_EXPERIENCE,
  DEFAULT_MIN_MEMBER_COUNT,
  DEFAULT_MIN_SALARY,
} from 'infra/constants'
import { makeAutoObservable } from 'mobx'
import {
  StartupPositionFilterParams,
  StartupPositionOrderByType,
  StartupPositionRemoteWorkType,
  StartupPositionStockOptionType,
} from 'types/common'

export class PositionFilterStore {
  // 필터
  parentPositionTypes: number[]
  positionTypes: number[]
  careerTypes: number[]
  minExperience: number
  maxExperience: number
  jobInterestLevels: number[]
  parentLocations: number[]
  locations: number[]
  serviceAreas: number[]
  techStacks: number[]
  minMemberCount: number
  maxMemberCount: number
  fundingRounds: number[]
  expectationKeywords: number[]
  hasSupervisor: boolean
  hasCto: boolean
  remoteWorkPreference: StartupPositionRemoteWorkType[]
  minSalary: number
  stockOption: StartupPositionStockOptionType[]
  // 상태값
  orderBy: StartupPositionOrderByType
  toggledParentPositionType: number
  toggledParentLocation: number
  toggledMinExperience: number
  toggledMaxExperience: number
  toggledMinMemberCount: number
  toggledMaxMemberCount: number
  toggleMinSalary: number
  isOpenJobConditionModal: boolean

  constructor(v?: StartupPositionFilterParams) {
    // 필터
    this.parentPositionTypes = v?.parentPositionTypes?.map(Number) || []
    this.positionTypes = v?.positionTypes?.map(Number) || []
    this.careerTypes = v?.careerTypes?.map(Number) || []
    this.minExperience = Number(v?.minExperience) || DEFAULT_MIN_EXPERIENCE
    this.maxExperience = Number(v?.maxExperience) || DEFAULT_MAX_EXPERIENCE
    this.jobInterestLevels = v?.jobInterestLevels?.map(Number) || []
    this.parentLocations = v?.parentLocations?.map(Number) || []
    this.locations = v?.locations?.map(Number) || []
    this.serviceAreas = v?.serviceAreas?.map(Number) || []
    this.techStacks = v?.techStacks?.map(Number) || []
    this.minMemberCount = Number(v?.minMemberCount) || DEFAULT_MIN_MEMBER_COUNT
    this.maxMemberCount = Number(v?.maxMemberCount) || DEFAULT_MAX_MEMBER_COUNT
    this.fundingRounds = v?.fundingRounds?.map(Number) || []
    this.expectationKeywords = v?.expectationKeywords?.map(Number) || []
    this.hasSupervisor = Boolean(v?.hasSupervisor) || false
    this.hasCto = Boolean(v?.hasCto) || false
    this.remoteWorkPreference = v?.remoteWorkPreference
      ? typeof v.remoteWorkPreference === 'string'
        ? [v.remoteWorkPreference]
        : v.remoteWorkPreference
      : []
    this.minSalary = Number(v?.minSalary) || DEFAULT_MIN_SALARY
    this.stockOption = v?.stockOption
      ? typeof v.stockOption === 'string'
        ? [v.stockOption]
        : v.stockOption
      : []
    // 상태값
    this.orderBy = '-updatedAt'
    this.toggledParentPositionType = 0
    this.toggledParentLocation = 0
    this.toggledMinExperience =
      Number(v?.minExperience) || DEFAULT_MIN_EXPERIENCE
    this.toggledMaxExperience =
      Number(v?.maxExperience) || DEFAULT_MAX_EXPERIENCE
    this.toggledMinMemberCount =
      Number(v?.minMemberCount) || DEFAULT_MIN_MEMBER_COUNT
    this.toggledMaxMemberCount =
      Number(v?.maxMemberCount) || DEFAULT_MAX_MEMBER_COUNT
    this.toggleMinSalary = Number(v?.minSalary) || DEFAULT_MIN_SALARY
    this.isOpenJobConditionModal = false
    makeAutoObservable(this)
  }

  setState<K extends keyof this>(key: K, value: this[K]) {
    this[key] = value
  }

  setFilter(v: StartupPositionFilterParams) {
    // 필터
    this.parentPositionTypes = v.parentPositionTypes?.map(Number) || []
    this.positionTypes = v.positionTypes?.map(Number) || []
    this.careerTypes = v.careerTypes?.map(Number) || []
    this.minExperience = Number(v.minExperience) || DEFAULT_MIN_EXPERIENCE
    this.maxExperience = Number(v.maxExperience) || DEFAULT_MAX_EXPERIENCE
    this.jobInterestLevels = v.jobInterestLevels?.map(Number) || []
    this.parentLocations = v.parentLocations?.map(Number) || []
    this.locations = v.locations?.map(Number) || []
    this.serviceAreas = v.serviceAreas?.map(Number) || []
    this.techStacks = v.techStacks?.map(Number) || []
    this.minMemberCount = Number(v.minMemberCount) || DEFAULT_MIN_MEMBER_COUNT
    this.maxMemberCount = Number(v.maxMemberCount) || DEFAULT_MAX_MEMBER_COUNT
    this.fundingRounds = v.fundingRounds?.map(Number) || []
    this.expectationKeywords = v.expectationKeywords?.map(Number) || []
    this.hasSupervisor = Boolean(v.hasSupervisor) || false
    this.hasCto = Boolean(v.hasCto) || false
    this.remoteWorkPreference = v.remoteWorkPreference
      ? typeof v.remoteWorkPreference === 'string'
        ? [v.remoteWorkPreference]
        : v.remoteWorkPreference
      : []
    this.minSalary = Number(v.minSalary) || DEFAULT_MIN_SALARY
    this.stockOption = v.stockOption
      ? typeof v.stockOption === 'string'
        ? [v.stockOption]
        : v.stockOption
      : []
    // 상태값
    this.toggledMinExperience =
      Number(v.minExperience) || DEFAULT_MIN_EXPERIENCE
    this.toggledMaxExperience =
      Number(v.maxExperience) || DEFAULT_MIN_EXPERIENCE
    this.toggledMinMemberCount =
      Number(v.minMemberCount) || DEFAULT_MIN_MEMBER_COUNT
    this.toggledMaxMemberCount =
      Number(v.maxMemberCount) || DEFAULT_MAX_MEMBER_COUNT
    this.toggleMinSalary = Number(v.minSalary) || DEFAULT_MIN_SALARY
  }

  reset() {
    // 필터
    this.parentPositionTypes = []
    this.positionTypes = []
    this.careerTypes = []
    this.minExperience = DEFAULT_MIN_EXPERIENCE
    this.maxExperience = DEFAULT_MAX_EXPERIENCE
    this.jobInterestLevels = []
    this.techStacks = []
    this.parentLocations = []
    this.locations = []
    this.serviceAreas = []
    this.minMemberCount = DEFAULT_MIN_MEMBER_COUNT
    this.maxMemberCount = DEFAULT_MAX_MEMBER_COUNT
    this.fundingRounds = []
    this.expectationKeywords = []
    this.hasSupervisor = false
    this.hasCto = false
    this.remoteWorkPreference = []
    this.minSalary = DEFAULT_MIN_SALARY
    this.stockOption = []
    // 상태값
    this.toggledParentLocation = 0
    this.toggledParentPositionType = 0
    this.toggledMinExperience = DEFAULT_MIN_EXPERIENCE
    this.toggledMaxExperience = DEFAULT_MAX_EXPERIENCE
    this.toggledMinMemberCount = DEFAULT_MIN_MEMBER_COUNT
    this.toggledMaxMemberCount = DEFAULT_MAX_MEMBER_COUNT
    this.toggleMinSalary = DEFAULT_MIN_SALARY
  }

  get isEmpty() {
    return (
      !this.parentPositionTypes.length &&
      !this.positionTypes.length &&
      !this.careerTypes.length &&
      !this.jobInterestLevels.length &&
      !Number(this.minExperience !== DEFAULT_MIN_EXPERIENCE) &&
      !Number(this.maxExperience !== DEFAULT_MAX_EXPERIENCE) &&
      !this.techStacks.length &&
      !this.parentLocations.length &&
      !this.locations.length &&
      !this.serviceAreas.length &&
      !this.fundingRounds.length &&
      !Number(this.hasCto) &&
      !Number(this.hasSupervisor) &&
      !Number(this.minMemberCount !== DEFAULT_MIN_MEMBER_COUNT) &&
      !Number(this.maxMemberCount !== DEFAULT_MAX_MEMBER_COUNT) &&
      !this.expectationKeywords.length &&
      !this.remoteWorkPreference.length &&
      !Number(this.minSalary !== DEFAULT_MIN_SALARY) &&
      !this.stockOption.length
    )
  }

  get params(): StartupPositionFilterParams {
    return {
      parentPositionTypes: this.parentPositionTypes,
      positionTypes: this.positionTypes,
      careerTypes: this.careerTypes,
      jobInterestLevels: this.jobInterestLevels,
      parentLocations: this.parentLocations,
      locations: this.locations,
      serviceAreas: this.serviceAreas,
      techStacks: this.techStacks,
      fundingRounds: this.fundingRounds,
      expectationKeywords: this.expectationKeywords,
      minMemberCount:
        this.minMemberCount === DEFAULT_MIN_MEMBER_COUNT
          ? undefined
          : this.minMemberCount,
      maxMemberCount:
        this.maxMemberCount === DEFAULT_MAX_MEMBER_COUNT
          ? undefined
          : this.maxMemberCount,
      minExperience:
        this.minExperience === DEFAULT_MIN_EXPERIENCE
          ? undefined
          : this.minExperience,
      maxExperience:
        this.maxExperience === DEFAULT_MAX_EXPERIENCE
          ? undefined
          : this.maxExperience,
      hasSupervisor: this.hasSupervisor || undefined,
      hasCto: this.hasCto || undefined,
      remoteWorkPreference: this.remoteWorkPreference,
      minSalary:
        this.minSalary === DEFAULT_MIN_SALARY ? undefined : this.minSalary,
      stockOption: this.stockOption,
    }
  }
}

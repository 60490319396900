import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { BaseText } from 'components/common/text'

const LINE_HEIGHT = 1.6

export const LineLimitedText: React.FC<{
  text: string
  lineClamp?: number
  fontSize?: number
  color?: string
}> = ({ text: desc, lineClamp = 3, fontSize = 15, color }) => {
  const textRef = useRef<HTMLSpanElement>(null)
  const [isShortened, setIsShortened] = useState<boolean | null>(null) // 줄임이 필요할지 아닐지 모르니, 초기값을 null로 줌
  useEffect(() => {
    if (!textRef.current || desc.includes('class=highlighted')) return // 하이라이팅된 컨텐츠가 포함되어있으면 줄이지 않음
    if (textRef.current.offsetHeight > LINE_HEIGHT * fontSize * lineClamp)
      setIsShortened(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textRef, fontSize, lineClamp])
  return (
    <div
      style={isShortened ? { cursor: 'pointer' } : undefined}
      onClick={(e) => {
        if (isShortened === null || isShortened === false) return
        e.stopPropagation()
        setIsShortened(!isShortened)
      }}
    >
      <DescText
        ref={textRef}
        isShortened={!!isShortened}
        lineClamp={lineClamp}
        color={color}
        fontSize={fontSize}
        dangerouslySetInnerHTML={{
          __html: isShortened ? desc.trim().replaceAll('\n', ' ') : desc.trim(),
        }}
      />
      {isShortened === true && (
        <MoreTextBtn fontSize={fontSize}>더보기</MoreTextBtn>
      )}
    </div>
  )
}

const DescText = styled(BaseText)<{
  isShortened: boolean
  lineClamp: number
  fontSize: number
  color?: string
}>`
  position: relative;
  display: -webkit-box;
  ${(p) =>
    p.isShortened &&
    `-webkit-box-orient: vertical;
    -webkit-line-clamp: ${p.lineClamp};
    overflow: hidden;
    text-overflow: ellipsis;`}
  word-wrap: break-word;
  line-height: ${LINE_HEIGHT};
  font-size: ${(p) => p.fontSize}px;
  ${(p) => p.color && `color: ${p.color};`}
`

const MoreTextBtn = styled(BaseText)<{ fontSize: number }>`
  white-space: nowrap;
  color: ${(p) => p.theme.color.gray1};
  font-size: ${(p) => p.fontSize}px;
`

import React from 'react'
import { Row } from 'components/common/layout'
import Image from 'next/legacy/image'
import styled from 'styled-components'
import { MediumText } from 'components/common/text'
import { EarthIcon, SproutIcon } from 'components/common/icon'
import { theme } from 'infra/theme'

const ICON_SIZE = 18

export const LocationMemberInfo: React.FC<{
  memberCount: number
  location: string
  devCount?: number
  fundingRound?: string | null
  serviceAreas?: string[]
}> = ({ memberCount, location, devCount, fundingRound, serviceAreas }) => {
  return (
    <LocationMemberContainer>
      <IconGapRow>
        <Image
          src='/location.svg'
          width={ICON_SIZE}
          height={ICON_SIZE}
          alt='location'
        />
        <SectionHeaderDesc>{location}</SectionHeaderDesc>
      </IconGapRow>
      {fundingRound && (
        <IconGapRow>
          <SproutIcon size={ICON_SIZE} color={theme.color.gray2} />
          <SectionHeaderDesc>{fundingRound}</SectionHeaderDesc>
        </IconGapRow>
      )}
      <IconGapRow>
        <Image
          src='/people.svg'
          width={ICON_SIZE}
          height={ICON_SIZE}
          alt='people'
        />
        <SectionHeaderDesc>{memberCount}명</SectionHeaderDesc>
      </IconGapRow>
      {!!devCount && (
        <IconGapRow>
          <Image
            src='/github.svg'
            width={ICON_SIZE}
            height={ICON_SIZE}
            alt='github'
          />
          <SectionHeaderDesc>{devCount}명</SectionHeaderDesc>
        </IconGapRow>
      )}
      {serviceAreas && serviceAreas.length > 0 && (
        <IconGapRow>
          <EarthIcon
            size={ICON_SIZE}
            color={theme.color.gray2}
            strokeWidth={1.5}
          />
          <SectionHeaderDesc>
            {serviceAreas[0]}
            {serviceAreas.length > 1 ? ` 외 ${serviceAreas.length - 1}개` : ''}
          </SectionHeaderDesc>
        </IconGapRow>
      )}
    </LocationMemberContainer>
  )
}

const IconGapRow = styled(Row)`
  gap: 4px;
`

const LocationMemberContainer = styled(Row)`
  flex-wrap: wrap;
  gap: 8px 16px;
`

const SectionHeaderDesc = styled(MediumText)`
  color: ${(p) => p.theme.color.gray2};
`

import React from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { MediumText } from 'components/common/text'
import {
  ScoutRequestAiAssessment,
  ScoutRequestAiAssessmentSection,
} from 'types/common'
import { useIsDesktopCSR } from 'utils/hooks'
import { MatchedText } from 'components/dashboard/scouts/profile/filter-assessment'

const renderContent = (sections: ScoutRequestAiAssessmentSection[]) => {
  const matched = sections.filter((x) => x.score > 0).length
  const total = sections.length
  return <MatchedText matched={matched} total={total} />
}

const SECTION_LABELS: Record<string, string> = {
  QUALIFICATION: '자격요건',
  PREFERRED: '우대사항',
}

export const PositionInfoAssessment: React.FC<{
  data: ScoutRequestAiAssessment
  isNarrow?: boolean
}> = ({ data, isNarrow }) => {
  const isDesktop = useIsDesktopCSR()
  const _isNarrow = !isDesktop || !!isNarrow

  return (
    <>
      {Object.entries(SECTION_LABELS).map(([type, label]) => {
        const sectionItems = data.sections.filter((x) => x.type === type)
        if (sectionItems.length === 0) return null

        return (
          <StyledRow key={type}>
            <Row>{renderContent(sectionItems)}</Row>
            <ItemText isNarrow={_isNarrow}>{label}</ItemText>
          </StyledRow>
        )
      })}
    </>
  )
}

const ItemText = styled(MediumText)<{ isNarrow: boolean }>`
  color: ${(p) => p.theme.color.black2};
`

const StyledRow = styled(Row)`
  gap: 8px;
`

import { usePositionDetail } from 'apis/hooks'
import { DeactivatedStatusModal } from 'components/misc/deactivated-status'
import { Column, Row } from 'components/common/layout'
import { MyLink } from 'components/common/link'
import { FunctionModal } from 'components/common/page-modal'
import { PositionButton } from 'components/positions/position-button'
import { PositionDetail } from 'components/positions/position-detail'
import { PositionHeader } from 'components/positions/position-header'
import {
  StartupSimple,
  StartupSimpleBox,
  StickyBox,
} from 'components/positions/startup-simple'
import { analytics } from 'infra/analytics'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useStores } from 'stores/globals'
import styled from 'styled-components'
import {
  updateStartupPositionInterested,
  viewStartupPosition,
} from 'utils/handlers'
import { usePositionIsInterestedUpdate } from 'utils/hooks'
import { PositionTable } from 'components/positions/position-table'

const PositionModalContent: React.FC<{
  positionId: number
  onClose: () => void
}> = ({ positionId, onClose }) => {
  const { data } = usePositionDetail(positionId)
  const [isDeactivatedModalOpen, setIsDeactivatedModalOpen] = useState(false)
  usePositionIsInterestedUpdate(
    [positionId],
    `/startup-positions/${positionId}`,
  )
  useEffect(() => {
    viewStartupPosition(positionId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (!data) return null
  return (
    <ContentContainer>
      <PositionHeaderContainer>
        <ModalLayout>
          <Column style={{ gap: 40 }}>
            <PositionHeader data={data} />
            <PositionTable data={{ ...data, hasCto: data.startup.hasCto }} />
          </Column>
        </ModalLayout>
      </PositionHeaderContainer>
      <ModalLayout>
        <StickyLayout>
          <Column style={{ flex: 1 }}>
            <PositionDetail
              data={{
                ...data,
                location: data.location?.name || data.startup.location,
                address: data.address || data.startup.address,
              }}
            />
          </Column>
          <StickyBox style={{ top: 60 }}>
            <MyLink
              href={`/startups/${data.startup.id}`}
              newTab={true}
              onClick={() =>
                analytics.track('click_po_startup', {
                  startupId: data.startup.id,
                  startupName: data.startup.name,
                  positionId: data.id,
                  positionName: data.name,
                })
              }
            >
              <StartupSimpleBox>
                <StartupSimple data={data} />
              </StartupSimpleBox>
            </MyLink>
            <PositionButton
              data={data}
              openDeactivatedModal={() => setIsDeactivatedModalOpen(true)}
              onSaveClick={() => {
                updateStartupPositionInterested(data.id, !data.isInterested)
                analytics.track('jobSearch_jsu_click_saveJobPost', {
                  startupId: data.startup.id,
                  startupName: data.startup.name,
                  positionId: data.id,
                  positionName: data.name,
                })
              }}
              onApplyClick={() => {
                analytics.track('jobSearch_jsu_click_applyJobPost', {
                  startupId: data.startup.id,
                  startupName: data.startup.name,
                  positionId: data.id,
                  positionName: data.name,
                })
                onClose()
              }}
            />
          </StickyBox>
        </StickyLayout>
      </ModalLayout>
      {isDeactivatedModalOpen && (
        <DeactivatedStatusModal
          onClose={() => setIsDeactivatedModalOpen(false)}
        />
      )}
    </ContentContainer>
  )
}

export const PositionModal: React.FC = observer(() => {
  const { positionModalStore } = useStores()
  if (!positionModalStore.isOpen) return null

  return (
    <FunctionModal
      width={1060}
      pageLink={`/positions/${positionModalStore.id}`}
      clickPageLink={() => {
        positionModalStore.closeModal()
      }}
      onClose={() => positionModalStore.closeModal()}
      newTab
    >
      <React.Suspense>
        <PositionModalContent
          positionId={positionModalStore.id}
          onClose={() => positionModalStore.closeModal()}
        />
      </React.Suspense>
    </FunctionModal>
  )
})

const PositionHeaderContainer = styled(Row)`
  margin-bottom: 40px;
  padding: 40px 0 20px;
`

const ModalLayout = styled(Column)`
  width: 860px;
  margin: 0 auto;
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
  }
`

const StickyLayout = styled(Row)`
  align-items: flex-start;
  gap: 20px;
`

const ContentContainer = styled(Column)`
  padding-bottom: 60px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding-right: 20px;
    padding-left: 20px;
  }
`

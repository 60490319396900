import {
  AccountAlertSetting,
  AdminFile,
  AdminFilesReqParams,
  AdminStartup,
  AdminStartupFunnel,
  AdminUser,
  AdminUserIdpCertificate,
  ApplicationItem,
  ApplicationsReqParams,
  BaseInfo,
  Company,
  DashboardScoutsCuration,
  DashboardCuratedScoutsRequests,
  DashboardIdpCertificateDetail,
  DashboardInitialScoutProposal,
  DashboardInterestedInMeParams,
  DashboardInterestedInMeProfileItem,
  DashboardScoutRequestOneQueryParams,
  DashboardPosition,
  DashboardScoutProfileItem,
  DashboardScoutProposalDetail,
  DashboardScoutRequestDetail,
  DashboardScoutSaveProfileListParams,
  DashboardScoutRequestListQueryParams,
  DashboardScoutsSummary,
  DashboardStartupDetail,
  DashboardTempScoutProposal,
  MatchedStartup,
  Paginated,
  PaginationParams,
  ProfileSaveListItem,
  RequestFormOptions,
  ScoutPosition,
  ScoutProfile,
  ScoutProposalDetail,
  Startup,
  StartupDetailForProposal,
  StartupInterestedInMe,
  StartupPage,
  StartupPIC,
  StartupPositionDetail,
  StartupPositionForSearch,
  StartupPositionReqParams,
  StartupSearchingReqParams,
  StartupUser,
  TempApplication,
  User,
  UserIdpCertificateDetail,
  School,
  SearchedStartup,
  AdminTrackingManualMatch,
  AdminTrackingManualMatchParams,
  SearchedUser,
  UserSearchingReqParams,
  AdminStatusInfo,
  AdminTrackingScoutProposal,
  AdminTrackingScoutProposalParams,
  AdminTrackingHire,
  AdminChatMessage,
  AdminTrackingApplicationParams,
  AdminTrackingPositionApplication,
  StartupPositionFilterParams,
  ScoutPreAnalysis,
  ScoutProposal,
  DashboardApplicationDetail,
  ApplicationDetail,
  DashboardScoutProposal,
  DashboardApplicationItem,
  NumberOrNull,
  ApplicationsParams,
  DashboardChatStatus,
  TrackingHireStatusInfo,
  TrackingHireBetaFilterParams,
  TrackingHireStartup,
  TrackingHireStartupSimple,
  TrackingHireUserListFilterParams,
  TrackingHireMatchedUser,
  DashboardScoutsTextToSearch,
  ProposalReqParams,
  ScoutProfileDrafts,
  DashboardApplicationSummary,
  ResumeAnalysisCharacterMatch,
  DashboardScoutsSearchOptions,
  ResumeAnalysisFactAssault,
  PaginatedWithBoosting,
  BoostingWithItem,
  ResumeAnalysisKeywordsMatch,
  DashboardScoutsSearchInfo,
  PaginatedWithTotalInfo,
  DashboardScoutSearchItem,
  DashboardScoutSearchesParams,
  AdminBrokingRequestsParams,
  AdminBrokingRequests,
  AdminBrokingRequestsConfirmed,
  StartupPositionCurationMe,
  StartupPositionCurationFeedbackInfo,
  DashboardApplicationPreInterview,
  PreInterviewQuestion,
  AdminPreInterviewItem,
  AdminActiveHireNeeds,
  AccountMeInterestedPosition,
  StartupPositionApplicationGenerate,
  DashboardPositionsInfo,
  AdminPerStartupCurationScoutRequest,
  AdminPerStartupCurationDetail,
  AdminPerStartupCuration,
  AdminPerStartupCurationParams,
  AdminPerStartupClosedCuration,
  AdminPerStartupClosedCurationParams,
  ScoutSearchCandidate,
  DashboardProposedScoutProfileItem,
  DashboardScoutProposalSettings,
} from 'types/common'
import { useEnvelopeSWR, useRemoveSWRErrorCache } from 'apis/envelope'
import {
  useIsLoggedIn,
  useIsLoggedInAdmin,
  useIsLoggedInStartup,
} from 'utils/hooks'
import Cookies from 'js-cookie'
import { COOKIE_KEY_USER } from 'infra/constants'
import { ParsedUrlQuery } from 'querystring'
import qs from 'query-string'

export const useStartups = (params: StartupSearchingReqParams) =>
  useEnvelopeSWR<Paginated<SearchedStartup>>(`/startups`, params)

export const usePositions = (params: StartupPositionReqParams) =>
  useEnvelopeSWR<Paginated<StartupPositionForSearch>>(
    `/startup-positions`,
    params,
  )

export const usePositionDetail = (id?: number) =>
  useEnvelopeSWR<StartupPositionDetail>(id ? `/startup-positions/${id}` : null)

export const useBaseInfo = () =>
  useEnvelopeSWR<BaseInfo>(`/services/base-info`, undefined, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

export const useStartupDetail = (id?: number, params?: { open: boolean }) =>
  useEnvelopeSWR<StartupPage>(id ? `/startups/${id}` : null, params)

export const useStartupPosition = (
  positionId: number,
  startupPage?: StartupPage,
) => {
  if (!startupPage) return null
  return (
    startupPage.curr.positions.find((position) => position.id === positionId) ||
    null
  )
}

export const useUser = ({ ssr }: { ssr?: boolean } = { ssr: true }) => {
  // hydration 이슈가 없는 csr 방식은 쿠키로 유저를 체크하여 바로 데이터 사용 가능
  const isLoggedIn = useIsLoggedIn()
  const _isLoggedIn = ssr ? isLoggedIn : !!Cookies.get(COOKIE_KEY_USER) //Cookie로 받는건 requireLogin으로 감싼 페이지 내부에서만 가능
  return useEnvelopeSWR<User | null>(_isLoggedIn ? '/accounts/me' : null)
}

export const useAdminUser = () => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<AdminUser | null>(isLoggedIn ? '/admin/me' : null)
}

export const useInterestedStartups = () => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<Startup[] | null>(
    isLoggedIn ? '/accounts/me/interested-startups' : null,
  )
}

export const useInterestedPositions = () => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<AccountMeInterestedPosition[] | null>(
    isLoggedIn ? '/accounts/me/interested-positions' : null,
  )
}

export const useApplications = (params: ApplicationsParams) => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<ApplicationItem[]>(
    isLoggedIn ? '/accounts/me/applications' : null,
    params,
  )
}

export const useTempApplication = (positionId: number) => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<TempApplication | null>(
    isLoggedIn ? `/startup-positions/${positionId}/applications/temp` : null,
  )
}

export const useDashboardApplicationsSummary = () => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardApplicationSummary[]>(
    isLoggedIn ? `/dashboard/applications/summary` : null,
  )
}

export const useDashboardApplications = (params: ApplicationsReqParams) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<Paginated<DashboardApplicationItem> | null>(
    isLoggedIn && params.applicationStatuses.length > 0
      ? `/dashboard/applications`
      : null,
    params,
  )
}

export const useStartupUser = () => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<StartupUser | null>(
    isLoggedIn ? '/dashboard/accounts/me' : null,
  )
}

export const useStartupPIC = () => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<StartupPIC[] | null>(
    isLoggedIn ? '/dashboard/accounts/me/startup-pic' : null,
  )
}

export const useDashboardPositions = () => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardPosition[] | null>(
    isLoggedIn ? '/dashboard/positions' : null,
  )
}

export const useDashboardRejectMsg = (id: number) => {
  return useEnvelopeSWR<{ rejectMessage: string }>(
    `/dashboard/applications/${id}/reject-message`,
  )
}

export const useDashboardResumeAcceptedMsg = (id: number) => {
  return useEnvelopeSWR<{ resumeAcceptMessage: string }>(
    `/dashboard/applications/${id}/resume-accept-message`,
  )
}

export const useDashboardStartupDetail = () => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardStartupDetail | null>(
    isLoggedIn ? '/dashboard/startup' : null,
  )
}

export const useAccountAlertSettings = () => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<AccountAlertSetting | null>(
    isLoggedIn ? '/accounts/me/alert-settings' : null,
  )
}

export const useRequestFormOptions = () =>
  useEnvelopeSWR<RequestFormOptions>('/scouts/requests/options', undefined, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

export const useDashboardScoutPositions = (id: number) =>
  useEnvelopeSWR<ScoutPosition[]>(`/dashboard/scouts/requests/${id}/positions`)

export const useScoutProposals = () => {
  const isLoggedIn = Cookies.get(COOKIE_KEY_USER) //Cookie로 받는건 requireLogin으로 감싼 페이지 내부에서만 가능
  return useEnvelopeSWR<ScoutProposal[]>(
    isLoggedIn ? '/scouts/proposals' : null,
  )
}

export const useDashboardScoutProposals = (params?: ProposalReqParams) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<Paginated<DashboardScoutProposal>>(
    isLoggedIn ? '/dashboard/scouts/proposals' : null,
    params,
  )
}

export const useDashboardScoutProposalSettings = () => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardScoutProposalSettings>(
    isLoggedIn ? '/dashboard/scouts/proposals/settings' : null,
  )
}

export const useProposalDetail = (id: NumberOrNull) => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<ScoutProposalDetail>(
    isLoggedIn && id ? `/scouts/proposals/${id}` : null,
  )
}

export const useDashboardProposalDetail = (id: NumberOrNull) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardScoutProposalDetail>(
    isLoggedIn && id ? `/dashboard/scouts/proposals/${id}` : null,
  )
}

export const useDashboardApplicationDetail = (id: NumberOrNull) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardApplicationDetail>(
    isLoggedIn && id ? `/dashboard/applications/${id}` : null,
  )
}

export const useApplicationDetail = (id: NumberOrNull) => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<ApplicationDetail>(
    isLoggedIn && id ? `/accounts/me/applications/${id}` : null,
  )
}

export const useInitialScoutProposal = () => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardInitialScoutProposal>(
    isLoggedIn ? `/dashboard/scouts/proposals/initial` : null,
  )
}

export const useTempScoutProposal = (
  requestId: number,
  isForceRead: boolean = false, // 임시저장된 제안서에 대해서만 응답값을 받을 수 있는데, 제안 완료 후에도 그 내용이 필요한 스카우트 마법사를 위해 isForceRead 사용
) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardTempScoutProposal>(
    isLoggedIn
      ? `/dashboard/scouts/requests/${requestId}/proposal${
          isForceRead ? `?isForceRead=true` : ''
        }`
      : null,
  )
}

export const useStartupDetailForProposal = (id: number) =>
  useEnvelopeSWR<StartupDetailForProposal>(`/startups/${id}/for-proposal`)

export const useDashboardScoutRequestDetail = (
  requestId?: number,
  params?: {
    applicationId?: number
    curationId?: number
    aiAssessmentSessionId?: number
  },
) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardScoutRequestDetail>(
    isLoggedIn && requestId ? `/dashboard/scouts/requests/${requestId}` : null,
    params,
  )
}

export const useNewProfile = (
  params: DashboardScoutRequestOneQueryParams & {
    limit: number // 인재평가 50명씩 해야해서 limit 항상 필요하고, 기본적으로 50(AI_ASSESSMENT_PROFILE_COUNT)으로 보내야 함
    offset?: number // 스카우트 마법사에서 사용
    fetchedAt?: number
  },
  shouldFetch: boolean = true, // api 호출하지 않으려면 false를 보낼 수 있음
) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<BoostingWithItem<DashboardScoutRequestDetail | null>>(
    isLoggedIn && shouldFetch ? `/dashboard/scouts/requests/one` : null,
    params,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  )
}

export const useDashboardScoutsSummary = () => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardScoutsSummary>(
    isLoggedIn ? `/dashboard/scouts/summary` : null,
  )
}

export const useScoutMyRequest = () => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<ScoutProfile>(isLoggedIn ? `/scouts/requests/me` : null)
}

export const useRequestInfoFillDrafts = () => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<ScoutProfileDrafts | null>(
    isLoggedIn ? `/scouts/requests/me/request-info-fill/drafts` : null,
    undefined,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )
}

export const useDashboardScoutRequests = (
  params?: DashboardScoutRequestListQueryParams,
  abortController?: AbortController,
) => {
  const isLoggedIn = useIsLoggedInStartup()
  // 기존 검색에서 Error (Abort) 발생했던 경우 Cache 삭제해 재요청 되도록 함.
  useRemoveSWRErrorCache('/dashboard/scouts/requests', params)

  return useEnvelopeSWR<PaginatedWithBoosting<DashboardScoutProfileItem>>(
    isLoggedIn ? `/dashboard/scouts/requests` : null,
    params,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
    {
      abortController: abortController,
    },
  )
}

export const useDashboardScoutRequestsCount = (
  params: DashboardScoutRequestOneQueryParams,
) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<{ total: number }>(
    isLoggedIn ? `/dashboard/scouts/requests/count` : null,
    params,
  )
}

export const useDashboardScoutRequestsInPositionCuration = (params: {
  searchId: number
}) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardScoutProfileItem[]>(
    isLoggedIn ? `/dashboard/scouts/requests/in-position-curation` : null,
    params,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )
}

export const useCandidatesForScoutSearch = (searchId: NumberOrNull) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<ScoutSearchCandidate[]>(
    isLoggedIn && searchId
      ? `/dashboard/scouts/searches/${searchId}/candidates`
      : null,
  )
}

export const useMatchedStartup = () => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<MatchedStartup[]>(
    isLoggedIn ? `/startups/matched` : null,
  )
}

export const useAdminFiles = (params: AdminFilesReqParams) =>
  useEnvelopeSWR<Paginated<AdminFile>>(`/admin/files`, params)

export const useMyIdpCertificateDetail = (id: number) => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<UserIdpCertificateDetail>(
    isLoggedIn ? `/scouts/requests/me/idp-certificates/${id}` : null,
  )
}

export const useAdminUserIdpCertificates = (params: PaginationParams) =>
  useEnvelopeSWR<Paginated<AdminUserIdpCertificate>>(
    `/admin/user-idp-certificates`,
    params,
  )

export const useAdminStartups = (params: PaginationParams) =>
  useEnvelopeSWR<Paginated<AdminStartup>>(`/admin/startups`, params)

export const useAdminPreInterviews = (params: PaginationParams) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<Paginated<AdminPreInterviewItem>>(
    isLoggedIn ? '/admin/pre-interviews' : null,
    params,
  )
}

export const useDashboardIdpCertDetail = (
  requestId: number,
  idpCertificateId: number,
) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardIdpCertificateDetail>(
    isLoggedIn
      ? `/dashboard/scouts/requests/${requestId}/idp-certificates/${idpCertificateId}`
      : null,
  )
}

export const useStartupsInterestedInMe = (params: PaginationParams) => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<Paginated<StartupInterestedInMe>>(
    isLoggedIn ? '/startups/interested-in-me' : null,
    params,
    {
      revalidateOnFocus: false,
    },
  )
}

export const useAdminStartupFunnel = (params: ParsedUrlQuery) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<AdminStartupFunnel | null>(
    isLoggedIn ? '/admin/startups/funnel' : null,
    params,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )
}

export const useDashboardScoutsSearchFilter = (id: number) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardScoutSearchItem | null>(
    isLoggedIn && id ? `/dashboard/scouts/searches/${id}` : null,
    undefined,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )
}

export const useDashboardScoutsSearches = (
  params: DashboardScoutSearchesParams,
) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<PaginatedWithTotalInfo<DashboardScoutsSearchInfo>>(
    isLoggedIn ? '/dashboard/scouts/searches' : null,
    params,
  )
}

export const useDashboardScoutsSaveList = () => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<ProfileSaveListItem[]>(
    isLoggedIn ? '/dashboard/scouts/save-list' : null,
  )
}

export const useDashboardScoutSaveProfileList = (
  id: number,
  params: DashboardScoutSaveProfileListParams,
) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<Paginated<DashboardScoutProfileItem>>(
    isLoggedIn ? `/dashboard/scouts/save-list/${id}/requests` : null,
    params,
  )
}

export const useDashboardInterestedInMe = (
  params: DashboardInterestedInMeParams,
) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<Paginated<DashboardInterestedInMeProfileItem>>(
    isLoggedIn ? '/dashboard/scouts/requests/interested-in-me' : null,
    params,
    {
      revalidateOnFocus: false,
    },
  )
}

export const useDashboardScoutRequestsProposed = (params: PaginationParams) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<Paginated<DashboardProposedScoutProfileItem>>(
    isLoggedIn ? '/dashboard/scouts/requests/proposed' : null,
    params,
  )
}

export const useSearchSchool = (keyword: string) => {
  return useEnvelopeSWR<School[]>('/services/search-school', {
    searchQuery: keyword,
  })
}

export const useSearchCompany = (params: {
  searchQuery: string
  shouldCheckExternal?: boolean
}) => {
  return useEnvelopeSWR<Company[]>('/services/search-company', params)
}

export const useAdminPerStartupCuration = (
  params?: Partial<AdminPerStartupCurationParams>,
) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<AdminPerStartupCuration[]>(
    isLoggedIn ? `/admin/per-startup-curations` : null,
    params,
  )
}

export const useAdminPerStartupCurationDetail = (curationId: number) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<AdminPerStartupCurationDetail>(
    isLoggedIn ? `/admin/per-startup-curations/${curationId}` : null,
  )
}

export const useAdminPerStartupCurationScoutRequests = (curationId: number) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<AdminPerStartupCurationScoutRequest[]>(
    isLoggedIn
      ? `/admin/per-startup-curations/${curationId}/scout-requests`
      : null,
  )
}

export const useCurations = () => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardScoutsCuration[]>(
    isLoggedIn ? '/dashboard/scouts/curations' : null,
  )
}

export const useCuratedRequests = (curationId: number) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardCuratedScoutsRequests>(
    isLoggedIn ? `/dashboard/scouts/curations/${curationId}/requests` : null,
  )
}

export const useDashboardChatStatus = () => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardChatStatus[]>(
    isLoggedIn ? `/dashboard/chats/step` : null,
  )
}

export const useUsers = (params: UserSearchingReqParams) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<Paginated<SearchedUser>>(
    isLoggedIn ? `/admin/users` : null,
    params,
  )
}

export const useAdminStatusInfo = () => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<AdminStatusInfo>(
    isLoggedIn ? `/admin/status-info` : null,
  )
}

export const useAdminTrackingManualMatches = (
  params: AdminTrackingManualMatchParams & PaginationParams,
) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<Paginated<AdminTrackingManualMatch>>(
    isLoggedIn ? `/admin/manual-matches` : null,
    params,
  )
}

export const useAdminTrackingScoutProposals = (
  params: AdminTrackingScoutProposalParams & PaginationParams,
) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<Paginated<AdminTrackingScoutProposal>>(
    isLoggedIn ? `/admin/scout-proposals` : null,
    params,
  )
}

export const useAdminTrackingHires = (params: PaginationParams) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<Paginated<AdminTrackingHire>>(
    isLoggedIn ? `/admin/hires` : null,
    params,
  )
}

export const useAdminPositionApplications = (
  params: AdminTrackingApplicationParams & PaginationParams,
) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<Paginated<AdminTrackingPositionApplication>>(
    isLoggedIn ? `/admin/startup-position-applications` : null,
    params,
  )
}

export const useAdminChatMessages = (queryParams: {
  scoutProposalId?: number
  positionApplicationId?: number
}) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<AdminChatMessage[]>(
    isLoggedIn ? `/admin/chat-messages?${qs.stringify(queryParams)}` : null,
  )
}

export const useTrackingHireStatusInfo = () => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<TrackingHireStatusInfo[]>(
    isLoggedIn ? `/admin/hires/tracking/status-info` : null,
  )
}

export const useAdminTrackingHireStartups = (
  params: TrackingHireBetaFilterParams,
) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<TrackingHireStartup[]>(
    isLoggedIn ? `/admin/hires/tracking/startups` : null,
    params,
  )
}

export const useAdminTrackingHireStartupSimple = (startupId: NumberOrNull) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<TrackingHireStartupSimple>(
    isLoggedIn && startupId
      ? `/admin/hires/tracking/startups/${startupId}`
      : null,
  )
}

export const useAdminTrackingHireMatchedUsers = (
  startupId: number,
  params: TrackingHireUserListFilterParams,
) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<TrackingHireMatchedUser[]>(
    isLoggedIn ? `/admin/hires/tracking/startups/${startupId}/users` : null,
    params,
  )
}

export const usePreAnalysis = (params: {
  positions: number[]
  experience: number
}) => {
  return useEnvelopeSWR<ScoutPreAnalysis>('/scouts/pre-analysis', params)
}

export const useScoutsPopUp = (params: StartupPositionFilterParams) => {
  return useEnvelopeSWR<{ matchedStartupCount: number }>(
    '/scouts/pop-up',
    params,
  )
}

export const useRealTimeStatus = () => {
  return useEnvelopeSWR<{
    startups: number
    proposals: number
    chatMessages: number
    openedProfiles: number
  }>('/scouts/real-time-status')
}

export const useDashboardScoutsTextToSearches = (params: PaginationParams) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardScoutsTextToSearch[]>(
    isLoggedIn ? `/dashboard/scouts/text-to-searches` : null,
    params,
  )
}

export const useResumeAnalysisCharacterMatch = (id: string) => {
  return useEnvelopeSWR<ResumeAnalysisCharacterMatch>(
    `/resume-analysis/character-match/${id}`,
  )
}

export const useResumeAnalysisFactAssault = (id: string) => {
  return useEnvelopeSWR<ResumeAnalysisFactAssault>(
    `/resume-analysis/fact-assault/${id}`,
  )
}

export const useResumeAnalysisKeywordsMatch = (id: string) => {
  return useEnvelopeSWR<ResumeAnalysisKeywordsMatch>(
    `/resume-analysis/keywords-match/${id}`,
    undefined,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )
}

export const useDashboardScoutsSearchOptions = () => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardScoutsSearchOptions>(
    isLoggedIn ? `/dashboard/scouts/search/options` : null,
  )
}

export const useMyCurationStartupPositions = () => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<StartupPositionCurationMe>(
    isLoggedIn ? `/startup-positions/curations/me` : null,
  )
}

export const useStartupPositionsCount = (
  params: StartupPositionFilterParams,
) => {
  return useEnvelopeSWR<{ total: number }>(`/startup-positions/count`, params)
}

export const useAdminBrokingRequests = (params: AdminBrokingRequestsParams) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<AdminBrokingRequests>(
    isLoggedIn ? `/admin/broking-requests` : null,
    params,
  )
}

export const useAdminBrokingRequestConfirmed = (
  params: AdminBrokingRequestsParams,
) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<AdminBrokingRequestsConfirmed>(
    isLoggedIn ? `/admin/broking-requests/confirmed` : null,
    params,
  )
}

export const useDashboardPreInterviewOption = () => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<{
    questions: PreInterviewQuestion[]
  }>(isLoggedIn ? `/dashboard/pre-interviews/option` : null)
}

export const useDashboardApplicationPreInterview = (applicationId: number) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardApplicationPreInterview>(
    isLoggedIn
      ? `/dashboard/applications/${applicationId}/pre-interview`
      : null,
  )
}

export const useStartupPositionCurationFeedbackInfo = () => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<StartupPositionCurationFeedbackInfo>(
    isLoggedIn ? `/startup-positions/curations/feedback-info` : null,
  )
}

export const useAdminActiveHireNeeds = () => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<AdminActiveHireNeeds>(
    isLoggedIn ? `/admin/active-hire-needs` : null,
  )
}

export const useStartupPositionCurationGenerate = (positionId: number) => {
  const isLoggedIn = useIsLoggedIn()
  return useEnvelopeSWR<StartupPositionApplicationGenerate>(
    isLoggedIn
      ? `/startup-positions/${positionId}/applications/generate`
      : null,
  )
}

export const useDashboardPositionsInfo = (params: {
  careerTypes: number[]
  positionTypes: number[]
  maxExperience?: number
  minExperience?: number
}) => {
  const isLoggedIn = useIsLoggedInStartup()
  return useEnvelopeSWR<DashboardPositionsInfo>(
    isLoggedIn ? `/dashboard/positions/info` : null,
    params,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )
}

export const useAdminPerStartupClosedCuration = (
  params?: Partial<AdminPerStartupClosedCurationParams>,
) => {
  const isLoggedIn = useIsLoggedInAdmin()
  return useEnvelopeSWR<AdminPerStartupClosedCuration>(
    isLoggedIn ? `/admin/per-startup-curations/closed` : null,
    params,
  )
}

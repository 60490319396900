import {
  DashboardScoutRequestOneQueryParams,
  DashboardScoutRequestSearchFilterParams,
  EditableCareer,
  EditableOtherActivity,
} from 'types/common'

export const initialCareer: EditableCareer = {
  name: '',
  image: null,
  position: '',
  started: { year: '', month: '' },
  ended: { year: '', month: '' },
  isWorking: false,
  techStacks: [],
  description: '',
  isVerified: false,
  isCompleted: false,
  company: null,
  isStartup: false,
  serviceAreas: [],
  link: '',
  isCreatedByUser: true,
}

export const initialOtherActivity: EditableOtherActivity = {
  title: '',
  description: '',
  started: { year: '', month: '' },
  ended: { year: '', month: '' },
  isOnGoing: false,
  isCompleted: false,
  techStacks: [],
}

// GET 응답값 형식
export const initialFilter: DashboardScoutRequestSearchFilterParams = {
  id: 0,
  name: '',
  positions: [],
  techStacks: [],
  workLevels: [],
  minExperience: 0,
  maxExperience: 10,
  minSalary: 0,
  maxSalary: 10000,
  idpCertificates: [],
  isEducationCs: false,
  isEducationFinished: false,
  isJobInterested: false,
  hasIntroduction: false,
  hasAccessibleAttachment: false,
  shouldMatchTalentStartupRequired: false,
  keywords: [],
  orderBy: '-updatedByUserAt',
  isExcludeViewedTalent: false,
  jobInterestLevels: [],
  schoolGroups: [],
  schools: [],
  educationCategories: [],
  isNotWorking: false,
  languageSkills: [],
  locations: [],
  isRemoteWorkExcluded: false,
  hasStartupCareerHistory: false,
  fundingRounds: [],
  serviceAreas: [],
  careerServiceAreas: [],
  aiAssessmentConditions: [],
}

// POST 요청값 형식
export const initialFilterParams: DashboardScoutRequestOneQueryParams = {
  positions: [],
  techStacks: [],
  workLevels: [],
  minExperience: 0,
  maxExperience: 10,
  minSalary: 0,
  maxSalary: 10000,
  idpCertificates: [],
  isEducationCs: false,
  isEducationFinished: false,
  isJobInterested: false,
  hasIntroduction: false,
  hasAccessibleAttachment: false,
  shouldMatchTalentStartupRequired: false,
  keywords: [],
  orderBy: '-updatedByUserAt',
  isExcludeViewedTalent: false,
  jobInterestLevels: [],
  schoolGroups: [],
  schools: [],
  educationCategories: [],
  isNotWorking: false,
  languageSkills: '',
  locations: [],
  isRemoteWorkExcluded: false,
  hasStartupCareerHistory: false,
  fundingRounds: [],
  serviceAreas: [],
  careerServiceAreas: [],
  aiAssessmentConditions: [],
}

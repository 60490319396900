//작업 수행 함수 처리

import { deleteRequest, postFormRequest, putRequest } from 'apis/fetcher'
import { emitter } from 'infra/event-emitter'
import { ChangeEvent } from 'react'
import {
  isPasswordValid,
  isFileMaxSize,
  isEndWithConsonant,
  isPenaltyNoticeHidden,
} from 'utils/validations'
import { mutate } from 'swr'
import {
  COOKIE_KEY_USER,
  DASHBOARD_SAVE_LIST_URL,
  HIDE_PENALTY_NOTICE_STORAGE_KEY,
  MILLISECONDS_PER_DAY,
  NOT_POSITION_CURATION_SEARCHES_DEFAULT_PARAMS,
  RECOMMEND_REQUESTED_AT_KEY,
} from 'infra/constants'
import { analytics } from 'infra/analytics'
import Cookies from 'js-cookie'
import { debounce } from 'lodash-es'
import {
  AlertSettingsPayload,
  ApplicationParams,
  DashboardChatStatusId,
  DashboardChatStatusPayload,
  DashboardScoutRequestOneQueryParams,
  HidePenaltyNoticeType,
  NumberOrNull,
  PrivateInfoDetectedResponse,
  ScoutProfile,
  ScoutSearchSessionEventType,
  WithIdLink,
} from 'types/common'
import Router, { NextRouter } from 'next/router'
import { customToast, requestToast } from 'components/common/toast'
import { ApplicationsFilterParamsProps } from 'stores/chat-rooms/chat-list-filter'
import { ProposalReqParams } from 'types/common'
import qs from 'query-string'
import {
  deleteStartupPositionInterests,
  postDashboardScoutSearch,
  postRequestAnalyticsStartupScoutSearchSessionEvents,
  postRequestDashboardAccountMeStartupPicAlert,
  postRequestDashboardApplicationScheduledRejectionCancel,
  postRequestDashboardScoutSaveList,
  postRequestDashboardScoutTalentRecommendRequest,
  postRequestScoutProposalAllowResume,
  postRequestStartupPositionView,
  postRequestStartupView,
  putDashboardAccountMe,
  putDashboardScoutSearch,
  putStartupPositionApplicationTemp,
  putStartupPositionInterests,
} from 'apis/request'
import { ProposalStore } from 'stores/scouts/proposal'
import { AutoProposalMessageStore } from 'stores/auto-proposal-message'

export const mutateProposals = (proposalsParams?: ProposalReqParams) => {
  mutate(
    `/dashboard/scouts/proposals?${
      proposalsParams ? qs.stringify(proposalsParams) : ''
    }`,
  )
}

export const mutateApplications = (
  applicationsParams?: ApplicationsFilterParamsProps,
) => {
  mutate(
    `/dashboard/applications?${
      applicationsParams ? qs.stringify(applicationsParams) : ''
    }`,
  )
}

// 개별 상태 변경 시
export const updateChatStep = async (
  proposalId: NumberOrNull,
  applicationId: NumberOrNull,
  chatStep: DashboardChatStatusId,
  proposalsParams?: ProposalReqParams,
  applicationsParams?: ApplicationsFilterParamsProps,
  requestId?: number,
) => {
  try {
    const payload: DashboardChatStatusPayload[] = [
      { proposalId, applicationId, chatStep },
    ]
    await requestToast(putRequest('/dashboard/chats/step', payload), {
      loading: '변경중',
      success: '채팅 상태가 변경되었습니다.',
    })
    mutate('/dashboard/chats/step')
    if (proposalId) {
      mutateProposals(proposalsParams)
      mutate(`/dashboard/scouts/proposals/${proposalId}`)
    }
    if (applicationId) {
      mutateApplications(applicationsParams)
      mutate(`/dashboard/applications/${applicationId}`)
    }
    requestId &&
      mutate(
        `/dashboard/scouts/requests/${requestId}${
          applicationId ? `?applicationId=${applicationId}` : ''
        }`,
      )
  } catch (e) {
    alert(e)
  }
}

export const updateStartupPositionInterested = async (
  positionId: number,
  isInterested: boolean,
) => {
  try {
    if (isInterested) {
      await requestToast(
        putStartupPositionInterests(positionId, { status: 'INTERESTED' }),
        {
          loading: '저장중',
          success: '마이페이지 - 나의 활동 - 관심 채용 공고에서 확인해보세요',
        },
      )
    } else {
      await requestToast(deleteStartupPositionInterests(positionId), {
        loading: '해제중',
        success: '해제되었습니다.',
      })
    }
    emitter.positionIsInterestedChanged.emit({ positionId, isInterested })
  } catch (e) {
    alert(e)
  }
}

export const setStartupIsInterested = async (
  startupId: number,
  isInterested: boolean,
) => {
  try {
    await requestToast(
      putRequest(`/startups/${startupId}/interests`, {
        value: !isInterested,
      }).then(() =>
        emitter.startupIsInterestedChanged.emit({ startupId, isInterested }),
      ),
      {
        loading: '저장중',
        success: `${
          isInterested
            ? '해제되었습니다.'
            : '마이페이지 > 나의 활동 > 관심 스타트업에서 확인해보세요'
        }`,
      },
    )
  } catch (err) {
    alert(err)
  }
}

export const uploadFile = async (
  e: ChangeEvent<HTMLInputElement>,
  url: string,
) => {
  try {
    if (!e.target.files) return
    if (!isFileMaxSize(e.target.files[0].size))
      throw '50MB 이하의 파일만 업로드 가능합니다.'
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    e.target.value = ''
    return await postFormRequest<WithIdLink>(url, formData).then(
      (res) => res.data,
    )
  } catch (e) {
    alert(e)
  }
}

export const savePw = async (password: string, confirmPw: string) => {
  if (!isPasswordValid(password)) {
    throw '비밀번호는 최소 8자 이상 문자, 숫자, 특수문자를 포함해야 합니다.'
  }
  if (password !== confirmPw) {
    throw '비밀번호가 일치하지 않아요.'
  }
  await requestToast(putDashboardAccountMe({ password }), {
    loading: '저장중',
    success: '비밀번호가 저장되었습니다.',
  })
}

export const addProfileSaveListItem = async (savedListNames: string[]) => {
  const name = prompt('새 목록 이름을 입력해주세요.')
  if (!name) return
  if (savedListNames.includes(name)) {
    alert('이미 존재하는 이름입니다.')
    addProfileSaveListItem(savedListNames)
    return
  }
  await requestToast(postRequestDashboardScoutSaveList({ name }), {
    loading: '저장중',
    success: '새 목록을 추가했습니다.',
  })
  analytics.track('click_dss_add_save_list', { name })
  return await mutate(DASHBOARD_SAVE_LIST_URL)
}

export const viewStartupPosition = (positionId: number) => {
  const isLoggedIn = !!Cookies.get(COOKIE_KEY_USER)
  isLoggedIn && postRequestStartupPositionView(positionId)
}

export const viewStartup = (startupId: number) => {
  const isLoggedIn = !!Cookies.get(COOKIE_KEY_USER)
  isLoggedIn && postRequestStartupView(startupId)
}

export const getProfileCompleteStatus = (profile?: ScoutProfile) => {
  return {
    isCompletedEducation: !!profile?.educations.length,
    isCompletedCareer:
      !!profile?.careers.length || !!profile?.otherActivities.length,
    isCompletedResume: !!profile?.files.length || !!profile?.links.length,
    isCompletedIntroduction:
      !!profile?.introduction || !!profile?.personalities.length,
    isCompletedExpectation:
      !!profile?.expectation.description ||
      !!profile?.expectation.keywords.length,
    isCompletedLanguage: !!profile?.languageSkills.length,
    isCompletedImage: !!profile?.image,
    isCompletedJobSearchingReason: !!profile?.jobSearchingReason,
  }
}

// 인재 탐색 이벤트 추척용
export const trackScoutSearchSessionEvent = (
  type: ScoutSearchSessionEventType,
  data?: object,
) => {
  postRequestAnalyticsStartupScoutSearchSessionEvents({
    type,
    data: JSON.stringify(data),
  })
  analytics.track('searchProfile_compu_click_cvrStartBtn', {
    type,
    ...(data || {}),
  })
}

export const preventClose = (e: BeforeUnloadEvent) => {
  e.preventDefault()
  e.returnValue = ''
}

export const preventLeave = (router: NextRouter) => {
  router.events.emit('routeChangeError')
  if (router.asPath !== window.location.pathname) {
    window.history.pushState('', '', router.asPath)
  }
  // 문자열로 던지면 센트리는 객체만 받기 때문에 오류가 발생함
  throw new Error('router change aborted')
}

export const scrollEventTrack = debounce(
  (eventName: string, element?: HTMLElement) => {
    const { scrollHeight, scrollTop, clientHeight } = element
      ? element
      : document.documentElement
    const scrollDepth =
      scrollTop === 0
        ? 0
        : Math.floor((Math.ceil(scrollTop + clientHeight) / scrollHeight) * 100)
    let result = 0
    if (scrollDepth >= 90) {
      result = 100
    } else if (scrollDepth >= 75) {
      result = 75
    } else if (scrollDepth >= 50) {
      result = 50
    } else if (scrollDepth >= 25) {
      result = 25
    }
    analytics.track(eventName, { scrollDepth: result })
  },
  100,
)

export const editAlertSettings = (
  picId: number,
  payload: AlertSettingsPayload[],
  isNotiActivating: boolean,
) =>
  requestToast(postRequestDashboardAccountMeStartupPicAlert(picId, payload), {
    loading: '요청중',
    success: `알림이 ${isNotiActivating ? '설정' : '해제'}되었어요.`,
  }).then(() => mutate('/dashboard/accounts/me/startup-pic'))

export const noticePrivateInfoDetected = (
  data: PrivateInfoDetectedResponse | undefined,
  detectedPosition: string,
): boolean => {
  if (!data) return false
  const { hasPhone, hasEmail, hasLink, isLinkAdded } = data
  if (!hasPhone && !hasEmail && !hasLink) return false
  const detectedFields = []
  hasPhone && detectedFields.push('전화번호')
  hasEmail && detectedFields.push('이메일')
  hasLink && detectedFields.push('외부 링크')
  const fieldsMessage = detectedFields.join(', ')
  customToast(
    `${fieldsMessage}${
      isEndWithConsonant(fieldsMessage) ? '은' : '는'
    } ${detectedPosition}에 기재할 수 없어서 가려두었어요.${
      isLinkAdded ? `\n가려진 링크는 첨부 자료 > 링크에 저장해두었어요 :)` : ''
    }`,
    { duration: isLinkAdded ? 6000 : 4000 },
  )
  return true
}

export const detectAndNoticePrivateInfoFromApplication = async (
  positionId: number,
  payload: ApplicationParams,
): Promise<boolean> => {
  // 우회 가능 정보 탐지하여 삭제
  const res = await putStartupPositionApplicationTemp(positionId, true, payload)
  if (!res) return false
  return noticePrivateInfoDetected(res.data, '지원 메시지')
}

export const allowResume = async (proposalId: number) => {
  try {
    await postRequestScoutProposalAllowResume(proposalId, {
      isResumeAllowed: true,
    })
    mutate(`/scouts/proposals/${proposalId}`)
  } catch (e) {
    alert(e)
  }
  analytics.track('click_cr_attach_publish_btn')
}

export const hidePenaltyNotice = (type: HidePenaltyNoticeType) => {
  if (isPenaltyNoticeHidden(type)) return
  const params = { type, expire: Date.now() + MILLISECONDS_PER_DAY }
  localStorage.setItem(HIDE_PENALTY_NOTICE_STORAGE_KEY, JSON.stringify(params))
}

export const requestRecommend = async (onRequest?: () => void) => {
  try {
    if (
      !confirm(
        '인재 추천을 요청하시겠어요?\n채용 매니저가 직접 연락 드리고 인재를 소싱해드려요.',
      )
    ) {
      return
    }
    await requestToast(
      postRequestDashboardScoutTalentRecommendRequest({ message: '' }).then(
        () => {
          localStorage.setItem(
            RECOMMEND_REQUESTED_AT_KEY,
            JSON.stringify(new Date()),
          )
          onRequest?.()
        },
      ),
      {
        loading: '전송중',
        success:
          '인재 추천 요청이 완료되었어요.\n채용 매니저가 곧 연락 드릴거예요.',
      },
      { duration: 6000 },
    )
  } catch (e) {
    alert(e)
  }
}

export const scrollIntoView = (id: string, options?: ScrollIntoViewOptions) => {
  setTimeout(() => {
    document
      .getElementById(id)
      ?.scrollIntoView(
        options ? options : { behavior: 'smooth', block: 'center' },
      )
  }, 100)
}

export const cancelRejection = async (
  applicationId: number,
  params?: ApplicationsFilterParamsProps,
) => {
  if (!confirm('불합격 예약을 취소할까요?')) return
  try {
    await requestToast(
      postRequestDashboardApplicationScheduledRejectionCancel(applicationId),
      { loading: '요청중', success: '불합격 취소되었습니다.' },
    )
    mutate(`/dashboard/applications/${applicationId}`)
    params && mutate(`/dashboard/applications?${qs.stringify(params)}`)
  } catch (e) {
    alert(e)
  }
}

export const mutateDashboardCuratedScoutsRequests = async (
  curationId: number,
) => mutate(`/dashboard/scouts/curations/${curationId}/requests`)

export const withLoadingCheck = async (
  isLoading: boolean,
  setIsLoading: (isLoading: boolean) => void,
  fn: () => Promise<void>,
) => {
  if (isLoading) return
  setIsLoading(true)
  try {
    await fn()
  } finally {
    setIsLoading(false)
  }
}

export const editScoutSearchName = async (
  searchId: number,
  name: string | undefined,
  mutateKey?: string,
) => {
  const result = prompt('이 탐색의 이름을 수정해주세요.', name || '새로운 탐색')
  if (result === null) return
  try {
    await putDashboardScoutSearch(searchId, { name: result })
    mutate(`/dashboard/scouts/searches/${searchId}`)
    mutate(mutateKey)
  } catch (e) {
    alert(e)
  }
}

export const setAutoProposalGenerationEnabled = async (
  isAutoProposalGenerationEnabled: boolean,
  proposalStore: ProposalStore,
  autoProposalMessageStore: AutoProposalMessageStore,
) => {
  try {
    // 자동 생성 설정을 on 하는 경우
    if (isAutoProposalGenerationEnabled) {
      const isProposalEmpty =
        !proposalStore.temp.proposeReason && !proposalStore.temp.appealPoints
      if (
        isProposalEmpty || // 내용이 모두 비어있거나
        confirm(
          '이미 작성된 내용을 버리고 지금 바로 메시지를 자동 생성해드릴까요?', // confirm 창에 확인한 경우
        )
      ) {
        // 즉시 자동생성 처리
        autoProposalMessageStore.requestAutoMessage(
          proposalStore.request.id,
          proposalStore.request.name,
        )
      }
    }
    await requestToast(
      putDashboardAccountMe({ isAutoProposalGenerationEnabled }).then(() =>
        mutate('/dashboard/accounts/me'),
      ),
      {
        loading: '요청중',
        success: `AI 메시지 자동 생성을 ${
          isAutoProposalGenerationEnabled ? '켰어요.' : '껐어요.'
        }`,
      },
    )
  } catch (e) {
    alert(e)
  }
}

export const terminateScoutWizard = async (searchId: number) => {
  await requestToast(
    putDashboardScoutSearch(searchId, {
      isScoutWizardTerminated: true,
    }).then(() => mutate(`/dashboard/scouts/searches/${searchId}`)),
    {
      loading: '저장중',
      success:
        '🥳 축하드려요 스카우트 마법사를 완료했어요!\n채팅 페이지에서 보낸 메시지를 확인해보세요!',
    },
    { duration: 5000 },
  )
  Router.push(`/dashboard/chat-rooms`)
}

export const createScoutSearchAndNavigate = async (
  params: DashboardScoutRequestOneQueryParams,
) => {
  const { data } = await postDashboardScoutSearch(params)
  if (!data) return
  await mutate(
    `/dashboard/scouts/searches?${qs.stringify(
      NOT_POSITION_CURATION_SEARCHES_DEFAULT_PARAMS,
    )}`,
  )
  Router.push(
    `/dashboard/scouts/search/${data.searchId}` +
      (params.aiAssessmentConditions.length > 0 // AI 인재평가 조건이 있는 경우
        ? '?hasAiConditions=true'
        : ''),
  )
}

export const deleteScoutSearchAndMutate = async (
  searchId: number,
): Promise<boolean> => {
  // 삭제 작업 성공 여부 반환
  const result = confirm('이 탐색을 삭제할까요?')
  if (!result) return false
  try {
    await deleteRequest(`/dashboard/scouts/searches/${searchId}`)
    await mutate(
      `/dashboard/scouts/searches?${qs.stringify(
        NOT_POSITION_CURATION_SEARCHES_DEFAULT_PARAMS,
      )}`,
      undefined,
      { revalidate: true },
    )
    return true
  } catch (e) {
    alert(e)
    return false
  }
}

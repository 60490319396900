import React from 'react'
import styled from 'styled-components'
import { useRequestFormOptions, useScoutMyRequest, useUser } from 'apis/hooks'
import { Card } from 'components/common/card'
import { ProfileDetail } from 'components/scouts/search/profile-detail'
import {
  ApplicationFulfillment,
  DashboardScoutRequestDetail,
} from 'types/common'

export const ProfilePreview: React.FC<{
  shouldInduceToFill?: boolean
  joinReason?: string
  fitReason?: string
  fulfillments?: ApplicationFulfillment
}> = ({ shouldInduceToFill, joinReason, fitReason, fulfillments }) => {
  const { data: user } = useUser()
  const { data: myProfile } = useScoutMyRequest()
  const { data: option } = useRequestFormOptions()
  if (!user || !myProfile || !option) return null
  const request: DashboardScoutRequestDetail = {
    ...myProfile,
    image: user.image,
    techStacks: user.interestedTechStacks,
    positions: user.interestedPositionTypes,
    workLevel: {
      id: myProfile.workLevel,
      name:
        option.workLevels.find((item) => myProfile.workLevel === item.id)
          ?.name || '',
    },
    files: myProfile.files.map((file) => {
      return { ...file, isAccessible: false }
    }),
    links: myProfile.links.map((link) => {
      return { ...link, isAccessible: false }
    }),
    locations: option.locations.filter((item) =>
      myProfile.locations.map((item) => item.id).includes(item.id),
    ),
    serviceAreas: option.serviceAreas.filter((item) =>
      myProfile.serviceAreas.includes(item.id),
    ),
    fundingRounds: option.fundingRounds.filter((item) =>
      myProfile.fundingRounds.includes(item.id),
    ),
    idpCertificates: myProfile.idpCertificates
      .filter((idp) => idp.isCertified)
      .map((idp) => idp.id),
    summary: null,
    assessMemo: null,
    saveLists: [],
    lastActiveAt: null,
    viewedMeAt: null,
    savedMeAt: null,
    viewedAt: null,
    lastListedAt: null,
    updatedAt: '',
    isProposed: false,
    proposalId: null,
    isCanceled: false,
    isRejected: false,
    isResumeAllowed: false,
    startupDismissed: false,
    hasConfirmedMeet: false,
    hasDoneMeet: false,
    status: null,
    hasTempScoutProposal: false,
    respondedCount: 0,
    receivedMessageCount: 0,
    hasPendingScoutProposal: false,
    hasApplication: false,
    birthYear: null,
    curatedAt: null,
    isDeleted: false,
    aiAssessment: null,
    managerComment: null,
    isCommonCurated: false,
    commonComment: null,
  }
  return (
    <Content>
      <ProfileDetail
        data={{ ...request, joinReason, fitReason, fulfillments }}
        shouldInduceToFill={shouldInduceToFill}
      />
    </Content>
  )
}

const Content = styled(Card)`
  padding: 56px 32px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 48px 16px;
  }
`

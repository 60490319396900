import { putRequestScoutRequestMe } from 'apis/request'
import { ConditionFormPage } from 'components/scouts/request-form/condition/condition-form-container'
import { makeAutoObservable } from 'mobx'
import { mutate } from 'swr'
import {
  ConditionFormParams,
  RemoteWorkType,
  ScoutProfile,
  ScoutRequestFormDataKey,
  ServiceArea,
  WithIdName,
} from 'types/common'
import { getSelectedChildrenByParent } from 'utils/formatters'

const initialRequestForm: ConditionFormParams = {
  workLevel: 0,
  jobInterestLevel: null,
  isActivelySeeker: false,
  locations: [],
  serviceAreas: [],
  memberCount: {
    min: 0,
    max: 20,
  },
  devCount: {
    min: 0,
    max: 10,
  },
  fundingRounds: [],
  salary: {
    min: 0,
    max: 10000,
  },
  miscOptions: {
    shouldCto: false,
    shouldSupervisor: false,
    shouldStockOption: false,
    remoteWorkPreference: null,
    isSalaryNegotiableWithStockOption: false,
  },
  requiredConditions: [
    { dataKey: 'locations', isRequired: false },
    { dataKey: 'serviceAreas', isRequired: false },
    { dataKey: 'fundingRounds', isRequired: false },
    { dataKey: 'memberCount', isRequired: false },
    { dataKey: 'devCount', isRequired: false },
    { dataKey: 'salary', isRequired: false },
    { dataKey: 'shouldSupervisor', isRequired: false },
    { dataKey: 'shouldCto', isRequired: false },
    { dataKey: 'shouldStockOption', isRequired: false },
    { dataKey: 'remoteWorkPreference', isRequired: false },
  ],
  lastSavedStep: 0,
}

export class ConditionFormStore {
  form: ConditionFormParams = initialRequestForm
  isLoading: boolean = false
  isLaterModalOpen: boolean = false
  hasMovedToLastSavedStep: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setIsSalaryNegotiableWithStockOption(v: boolean) {
    this.form.miscOptions.isSalaryNegotiableWithStockOption = v
  }

  setRemoteWorkPreference(v: RemoteWorkType) {
    this.form.miscOptions.remoteWorkPreference = v
  }

  setShouldStockOption(v: boolean) {
    this.form.miscOptions.shouldStockOption = v
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  setIsLaterModalOpen(v: boolean) {
    this.isLaterModalOpen = v
  }

  setHasMovedToLastSavedStep(v: boolean) {
    this.hasMovedToLastSavedStep = v
  }

  setMyRequest(myRequest: ScoutProfile) {
    this.form = {
      workLevel: myRequest.workLevel,
      jobInterestLevel: myRequest.jobInterestLevel,
      isActivelySeeker: myRequest.isActivelySeeker,
      locations: myRequest.locations.map((item) => item.id),
      serviceAreas: myRequest.serviceAreas,
      memberCount: myRequest.memberCount,
      devCount: myRequest.devCount,
      fundingRounds: myRequest.fundingRounds,
      salary: myRequest.salary,
      miscOptions: myRequest.miscOptions,
      requiredConditions: myRequest.requiredConditions,
      lastSavedStep: myRequest.lastSavedStep,
    }
  }

  setWorkLevel(id: number) {
    if (this.form.workLevel === id) return
    this.form.workLevel = id
    this.form.jobInterestLevel = null
  }

  setJobInterestLevel(id: number) {
    this.form.jobInterestLevel = id
  }

  setIsActivelySeeker(v: boolean) {
    this.form.isActivelySeeker = v
  }

  resetLocations() {
    this.form.locations = []
  }

  toggleLocations(id: number) {
    if (this.form.locations?.includes(id)) {
      this.form.locations = this.form.locations.filter(
        (location) => location !== id,
      )
    } else {
      this.form.locations = [...this.form.locations, id]
    }
  }

  setLocations(ids: number[]) {
    this.form.locations = ids
  }

  setMiscOptions(dataKey: 'shouldCto' | 'shouldSupervisor') {
    this.form.miscOptions[dataKey] = !this.form.miscOptions[dataKey]
  }

  setServiceAreas(v: WithIdName) {
    if (this.form.serviceAreas.includes(v.id)) {
      this.form.serviceAreas = this.form.serviceAreas.filter(
        (area) => area !== v.id,
      )
    } else {
      this.form.serviceAreas = [...this.form.serviceAreas, v.id]
    }
  }

  setMemberCount(v: number[]) {
    this.form.memberCount.min = v[0]
    this.form.memberCount.max = v[1]
  }

  setDevCount(v: number[]) {
    this.form.devCount.min = v[0]
    this.form.devCount.max = v[1]
  }

  setFundingRounds(id: number) {
    if (this.form.fundingRounds.includes(id)) {
      this.form.fundingRounds = this.form.fundingRounds.filter(
        (item) => item !== id,
      )
    } else {
      this.form.fundingRounds = [...this.form.fundingRounds, id]
    }
  }

  setSalary(v: number) {
    this.form.salary.min = v
  }

  addRequiredConditions(key: ScoutRequestFormDataKey) {
    if (
      this.form.requiredConditions.filter((item) => item.isRequired).length >= 5
    )
      return
    this.form.requiredConditions = this.form.requiredConditions.map((item) => {
      if (item.dataKey === key) item.isRequired = true
      return item
    })
  }

  removeRequiredConditions(key: ScoutRequestFormDataKey) {
    this.form.requiredConditions = this.form.requiredConditions.map((item) => {
      if (item.dataKey === key) item.isRequired = false
      return item
    })
  }

  // 저장할 수 있는 유효한 상태인지(필수 항목들이 입력되었는지)
  isValid(pageName: ConditionFormPage) {
    switch (pageName) {
      case 'job-search-status':
        if (
          !this.form.workLevel ||
          (this.form.workLevel === 1 && !this.form.jobInterestLevel) ||
          (this.form.workLevel === 3 && !this.form.jobInterestLevel)
        )
          return false
        return true
      case 'location':
        if (
          (this.form.miscOptions.remoteWorkPreference === null &&
            !this.form.locations?.length) ||
          (this.form.miscOptions.remoteWorkPreference ===
            'PART_REMOTE_NECESSARY' &&
            !this.form.locations?.length)
        )
          return false
        return true
    }
  }

  // 저장하고 넘어가기 위해 필수는 아니지만(선택 항목들), 입력되었는지
  isFilled(pageName: ConditionFormPage) {
    switch (pageName) {
      case 'industries':
        if (!this.form.serviceAreas.length) return false
        return true
      case 'size':
        if (
          this.form.memberCount.min === 0 &&
          this.form.memberCount.max === 20 &&
          this.form.devCount.min === 0 &&
          this.form.devCount.max === 10 &&
          !this.form.miscOptions.shouldCto &&
          !this.form.miscOptions.shouldSupervisor
        )
          return false
        return true
      case 'investment':
        if (!this.form.fundingRounds.length) return false
        return true
      case 'salary':
        if (
          this.form.salary.min === 0 &&
          this.form.salary.max === 10000 &&
          !this.form.miscOptions.shouldStockOption
        )
          return false
        return true
    }
  }

  removeWrongRequiredConditions(pageName: ConditionFormPage) {
    switch (pageName) {
      case 'location':
        if (!this.form.locations?.length)
          this.removeRequiredConditions('locations')
        if (this.form.miscOptions.remoteWorkPreference === null)
          this.removeRequiredConditions('remoteWorkPreference')
        break
      case 'industries':
        if (!this.form.serviceAreas.length)
          this.removeRequiredConditions('serviceAreas')
        break
      case 'size':
        if (this.form.devCount.min === 0 && this.form.devCount.max === 10)
          this.removeRequiredConditions('devCount')
        if (this.form.memberCount.min === 0 && this.form.memberCount.max === 20)
          this.removeRequiredConditions('memberCount')
        if (!this.form.miscOptions.shouldSupervisor)
          this.removeRequiredConditions('shouldSupervisor')
        if (!this.form.miscOptions.shouldCto)
          this.removeRequiredConditions('shouldCto')
        break
      case 'investment':
        if (
          !this.form.fundingRounds.length ||
          this.form.fundingRounds.length === 4
        )
          this.removeRequiredConditions('fundingRounds')
        break
      case 'salary':
        if (this.form.salary.min === 0 && this.form.salary.max === 10000)
          this.removeRequiredConditions('salary')
        if (!this.form.miscOptions.shouldStockOption)
          this.removeRequiredConditions('shouldStockOption')
        break
    }
  }

  async save(lastSavedStep: number, pageName: ConditionFormPage) {
    this.removeWrongRequiredConditions(pageName)
    const payload = {
      ...this.form,
      lastSavedStep,
      miscOptions: this.form.miscOptions,
      requiredConditions: this.form.requiredConditions,
    }
    this.setIsLoading(true)
    return putRequestScoutRequestMe(payload).then(() => {
      mutate('/scouts/requests/me')
      this.setIsLoading(false)
    })
  }

  getSelectedServiceAreaCount(serviceAreas: ServiceArea[]) {
    let count = 0
    const selectedChildrenByParent = getSelectedChildrenByParent(
      serviceAreas,
      serviceAreas.filter((item) => this.form.serviceAreas.includes(item.id)),
    )

    selectedChildrenByParent.forEach((parent) => {
      if (parent.selectedChildren.length === 0) return

      if (parent.total === parent.selectedChildren.length) {
        // Case 1: 부모 전체 선택
        count += 1
      } else {
        // Case 2: 개별 자식 선택 (비즈니스 모델 제외)
        count += parent.selectedChildren.length
      }
    })
    return count
  }
}

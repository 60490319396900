import React from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import {
  ScoutRequestAiAssessment,
  ScoutRequestFilterAssessment,
} from 'types/common'
import { AiAssessmentDescription } from 'components/dashboard/scouts/profile/ai-assessment-description'
import { FilterAssessment } from 'components/dashboard/scouts/profile/filter-assessment'
import { AssessmentScore } from 'components/dashboard/scouts/profile/assessment-score'
import { Card } from 'components/common/card'
import { AssessmentDescText } from 'components/dashboard/scouts/profile/styles'
import { PositionInfoAssessment } from 'components/dashboard/scouts/profile/position-info-assessment'

export const ProfileDetailAssessment: React.FC<{
  assessmentScore: number
  aiAssessment: ScoutRequestAiAssessment
  filterAssessment: ScoutRequestFilterAssessment
}> = ({ assessmentScore, aiAssessment, filterAssessment }) => {
  return (
    <Container>
      <AiAssessmentWrapper>
        <AssessmentScore score={assessmentScore} />
        <SummaryCard>
          <AssessmentDescText>{aiAssessment.summary}</AssessmentDescText>
        </SummaryCard>
      </AiAssessmentWrapper>
      <FilterAssessmentWrapper>
        {aiAssessment.sections[0]?.type === 'CUSTOM' ? (
          <FilterAssessment data={filterAssessment} />
        ) : (
          <PositionInfoAssessment data={aiAssessment} />
        )}
      </FilterAssessmentWrapper>
      <AiAssessmentDescription data={aiAssessment} />
    </Container>
  )
}

export const Container = styled(Column)`
  gap: 24px;
`

const FilterAssessmentWrapper = styled(Row)`
  gap: 16px;
  flex-wrap: wrap;
`

const AiAssessmentWrapper = styled(Column)`
  gap: 16px;
`

const SummaryCard = styled(Card)`
  padding: 12px;
  background-color: ${(p) => p.theme.color.background};
  span {
    line-height: 22px;
  }
`

import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { PositionApplicationStore } from 'stores/position-application'
import { Column } from 'components/common/layout'
import { CheckboxChip, ChipSize, ChipType } from 'components/common/chip'
import { TextArea } from 'components/common/input'
import { useDebounce } from 'utils/hooks'
import { putStartupPositionApplicationTemp } from 'apis/request'
import { ApplicationFulfillmentParams } from 'types/common'

export const PositionApplicationFulfillmentList: React.FC<{
  store: PositionApplicationStore
  type: 'preferred' | 'task' | 'qualification'
}> = observer(({ store, type }) => {
  const saveTempFulfillment = useDebounce(
    1000,
    async (v: ApplicationFulfillmentParams) => {
      await putStartupPositionApplicationTemp(
        store.positionDetail?.id || 0,
        false,
        { fulfillments: [v] },
      )
      store.setState('isSavingFulfillment', false)
    },
  )
  return (
    <>
      {store.fulfillments[type].map((item, index) => (
        <Column style={{ gap: 8 }} key={item.conditionId}>
          <CheckboxChip
            size={ChipSize.SMALL}
            variant={ChipType.FILLED_PRIMARY}
            isSelected={item.isFit}
            isTextWrap
            onClick={async () => {
              store.setState('isSavingFulfillment', true)
              store.setFulfillment(type, index, { isFit: !item.isFit })
              await putStartupPositionApplicationTemp(
                store.positionDetail?.id || 0,
                false,
                {
                  fulfillments: [
                    {
                      conditionId: item.conditionId,
                      description: item.isFit ? '' : item.description, // 현재 기준값으로 판단
                      isFit: !item.isFit,
                    },
                  ],
                },
              )
              store.setState('isSavingFulfillment', false)
            }}
          >
            {item.condition}
          </CheckboxChip>
          {item.isFit && (
            <ConditionTextArea
              placeholder='만족하는 이유를 구체적으로 설명하면 적합함을 더욱 어필할 수 있어요! (최대 200자)'
              value={item.description || ''}
              onChange={(e) => {
                if (e.target.value.length > 200) return
                store.setState('isSavingFulfillment', true)
                store.setFulfillment(type, index, {
                  description: e.target.value,
                })
                saveTempFulfillment({
                  conditionId: item.conditionId,
                  description: e.target.value,
                  isFit: item.isFit,
                })
              }}
            />
          )}
        </Column>
      ))}
    </>
  )
})

const ConditionTextArea = styled(TextArea)`
  height: 80px;
`

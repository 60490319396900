import React, { useMemo } from 'react'
import styled from 'styled-components'
import qs from 'query-string'
import { useStores } from 'stores/globals'
import { Modal } from 'components/common/modal'
import { Column, Row } from 'components/common/layout'
import { SemiBoldText } from 'components/common/text'
import { FiChevronRight } from 'react-icons/fi'
import { BtnSize, Button } from 'components/common/button'
import { useRequestFormOptions, useScoutMyRequest, useUser } from 'apis/hooks'
import { usePreferenceRequestForm } from 'utils/hooks'
import { observer } from 'mobx-react'
import { Tag, TagContainer } from 'components/common/tag'
import { useRouter } from 'next/router'
import { getJobConditionToPositionFilterParams } from 'utils/formatters'
import {
  DASHBOARD_FILTER_EXPERIENCED_JOB_INTEREST_LEVELS,
  DASHBOARD_FILTER_INTERN_JOB_INTEREST_LEVELS,
  POSITION_FILTER_QUERY_PARAMS_KEY,
} from 'infra/constants'
import { RequestFormOptions, ScoutProfile, User } from 'types/common'
import toast from 'react-hot-toast'
import { analytics } from 'infra/analytics'

const JOB_INTEREST_LEVELS = [
  ...DASHBOARD_FILTER_EXPERIENCED_JOB_INTEREST_LEVELS,
  ...DASHBOARD_FILTER_INTERN_JOB_INTEREST_LEVELS,
]

export const JobConditionModal: React.FC = observer(() => {
  const { positionFilterStore } = useStores()
  const { data: user } = useUser()
  const { data: profile } = useScoutMyRequest()
  const { data: options } = useRequestFormOptions()

  if (
    !user ||
    !profile ||
    !options ||
    !positionFilterStore.isOpenJobConditionModal
  )
    return null
  return <JobCondition user={user} profile={profile} options={options} />
})

const JobCondition: React.FC<{
  user: User
  profile: ScoutProfile
  options: RequestFormOptions
}> = observer(({ user, profile, options }) => {
  const { positionFilterStore } = useStores()
  const router = useRouter()
  const memoizedRequest = useMemo(
    () => ({
      ...profile,
      locations: profile.locations.map((item) => item.id) || [],
    }),
    [profile],
  )
  const preference = usePreferenceRequestForm(memoizedRequest, options)
  const jobInterestLevel =
    JOB_INTEREST_LEVELS.find((item) => item.id === profile.jobInterestLevel)
      ?.name || ''
  return (
    <Modal
      width={400}
      disableCloseBtn
      onClose={() =>
        positionFilterStore.setState('isOpenJobConditionModal', false)
      }
    >
      <Container>
        <Column style={{ gap: 24, alignItems: 'center' }}>
          <MainText>이 구직 조건으로 채용 공고를 찾아볼게요</MainText>
          <TagContainer style={{ justifyContent: 'center' }}>
            {preference.conditions.map((item) => (
              <Tag key={item.dataKey}>{item.value}</Tag>
            ))}
            {jobInterestLevel && <Tag>{jobInterestLevel}</Tag>}
          </TagContainer>
          <TagBtn
            onClick={() => {
              router.push(
                '/scouts/request-form/job-search-status?shouldApplyConditions=true',
              )
              positionFilterStore.setState('isOpenJobConditionModal', false)
              analytics.track('click_job_condition_modal_update_condition_btn')
            }}
          >
            구직 조건 수정하고 찾기
            <FiChevronRight size={18} strokeWidth={2} />
          </TagBtn>
        </Column>
        <Button
          size={BtnSize.MEDIUM}
          onClick={() => {
            const params = getJobConditionToPositionFilterParams(
              user,
              profile,
              options?.locations || [],
            )
            positionFilterStore.setFilter(params)
            const queryParams = qs.stringify(params)
            localStorage.setItem(POSITION_FILTER_QUERY_PARAMS_KEY, queryParams)
            !router.pathname.includes('/positions') && router.push('/positions')
            positionFilterStore.setState('isOpenJobConditionModal', false)
            toast('내 구직 조건에 딱 맞게 필터를 설정했어요')
            analytics.track('click_job_condition_modal_search_btn')
          }}
        >
          바로 찾아보기
        </Button>
      </Container>
    </Modal>
  )
})

const Container = styled(Column)`
  gap: 40px;
  padding: 20px;
  align-items: center;
`

const MainText = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.h5};
`

const TagBtn = styled(Row)`
  gap: 2px;
  width: fit-content;
  border-radius: 16px;
  padding: 4px 4px 4px 12px;
  font-size: 14px;
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  background-color: ${(p) => p.theme.color.background5};
  color: ${(p) => p.theme.color.black4};
  cursor: pointer;
`

// NOTE(son) amplitude 구조분해 하지않고 하나의 import로 사용하면 error 발생
import * as amplitude from '@amplitude/analytics-browser'
import {
  AMPLITUDE_API_KEY,
  COOKIE_DOMAIN,
  IS_BROWSER,
  LOG_ROCKET_KEY,
  MIXPANEL_API_KEY,
  MS_CLARITY_KEY,
} from 'infra/constants'
import mixpanel from 'mixpanel-browser'
import Bugsnag from '@bugsnag/js'
import { setUser as sentrySetUser } from '@sentry/nextjs'
import { StartupUserTokenPayload, UserTokenPayload } from 'types/common'
import { clarity } from 'react-microsoft-clarity'
import LogRocket from 'logrocket'

const STARTUP_USER_PREFIX = 'su_'
class Analytics {
  async init() {
    amplitude.init(AMPLITUDE_API_KEY as string, {
      defaultTracking: true,
      cookieOptions: {
        domain: COOKIE_DOMAIN,
        expiration: 365,
        secure: true,
        sameSite: 'Lax',
      },
    })
    mixpanel.init(MIXPANEL_API_KEY as string)
    // mixpanel은 init시 distinct_id가 새로 할당되기 때문에 id를 연쇄적으로 연결한다.
    const storedDistinctId = localStorage.getItem('mixpanel_distinct_id')
    const currentDistinctId = mixpanel.get_distinct_id()
    if (storedDistinctId && storedDistinctId !== currentDistinctId) {
      mixpanel.alias(storedDistinctId, currentDistinctId)
    }
    localStorage.setItem('mixpanel_distinct_id', currentDistinctId)
    clarity.init(MS_CLARITY_KEY as string)
  }

  traffic(eventName: string) {
    IS_BROWSER && this.track(eventName)
  }

  track(eventName: string, eventProps?: Record<string, unknown>) {
    amplitude.track(eventName, eventProps)
    mixpanel.track(eventName, eventProps)
  }

  get userId() {
    return amplitude.getUserId()
  }

  _setUser(userId: string) {
    amplitude.setUserId(userId)
    mixpanel.people.set({ userId })
    Bugsnag.setUser(userId)
    sentrySetUser({ id: userId }) // sentry
    clarity.identify(userId, { friendlyName: userId }) // 대시보드에서 표시되는지 확인이 안됨. 추후 수정 필요할 수 있음
    LogRocket.identify(userId)
  }

  _clearUser() {
    amplitude.setUserId(undefined)
    mixpanel.reset()
    Bugsnag.setUser(undefined)
    sentrySetUser(null) // sentry
    this.setOptOut(false)
  }

  _getStringUserId(id: number, isStartupUser: boolean) {
    // NOTE(son): amplitude 에서 id값 5자 이상 요구
    // https://www.docs.developers.amplitude.com/analytics/apis/http-v2-api/#options
    let userId = String(id).padStart(5, '0')
    if (isStartupUser) {
      userId = `${STARTUP_USER_PREFIX}${userId}`
    }
    return userId
  }

  setUser(payload: UserTokenPayload) {
    if (!payload.user_id) throw 'id should not be empty!'
    // use identify if user id is already initialized with user id
    if (this.userId && this.userId.startsWith(STARTUP_USER_PREFIX)) {
      this.setIdentify({ extraUserId: String(payload.user_id) })
      return
    }
    this.setOptOut(payload.is_fake)
    const userId = this._getStringUserId(payload.user_id, false)
    this._setUser(userId)
    this.setIdentify({ jsuName: payload.username })
  }

  setStartupUser(payload: StartupUserTokenPayload) {
    if (!payload.startup_user_id) throw 'id should not be empty!'
    this.setOptOut(payload.is_fake)
    const userId = this._getStringUserId(payload.startup_user_id, true)
    this._setUser(userId)
    this.setIdentify({
      compuName: payload.startup_name,
      startupId: String(payload.startup_id),
      userId: userId,
    })
  }

  clearUser() {
    // ignore if startup user profile exists
    if (this.userId && this.userId.startsWith(STARTUP_USER_PREFIX)) return
    this._clearUser()
  }

  clearStartupUser() {
    // ignore if dev user profile exists
    if (this.userId && !this.userId.startsWith(STARTUP_USER_PREFIX)) return
    this._clearUser()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setIdentify(params: Record<string, any>) {
    const identify = new amplitude.Identify()
    if (!params) return
    Object.keys(params).forEach((item) => identify.set(item, params[item]))
    amplitude.identify(identify)
    // -----
    mixpanel.people.set(params)
    // LogRocket
    if (params.startupId) {
      LogRocket.identify(params?.userId, {
        name: params.compuName,
        startupId: params.startupId,
      })
    }
    // clarity
    if (this.userId && params.compuName) {
      clarity.identify(this.userId, { friendlyName: params.compuName }) // 대시보드에서 표시되는지 확인이 안됨. 추후 수정 필요할 수 있음
    }
  }

  addIdentify(key: string, value: number) {
    const identify = new amplitude.Identify()
    identify.add(key, value)
    amplitude.identify(identify)
    // -----
    mixpanel.people.increment(key, value)
  }

  // fake login 시 트래킹 추적 중지
  setOptOut(optOut: boolean) {
    amplitude.setOptOut(optOut)
    if (optOut) {
      mixpanel.opt_out_tracking()
    } else {
      mixpanel.opt_in_tracking()
    }
  }

  // LogRocket은 init 하자마자 세션 녹화가 시작되고 횟수가 카운팅되므로 필요할 때만 호출
  initLogRocket() {
    LogRocket.init(LOG_ROCKET_KEY as string)
  }
}

export const analytics = new Analytics()

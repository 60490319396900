import React from 'react'
import { StartupPositionDetail } from 'types/common'
import { Column } from 'components/common/layout'
import { BoldText, DescText } from 'components/common/text'
import { LocationMemberInfo } from 'components/positions/location-member-info'
import { Hashtag } from 'components/common/hashtag'
import styled from 'styled-components'
import { OutlinedCard } from 'components/common/card'

export const StartupSimple: React.FC<{
  data: StartupPositionDetail
}> = ({ data }) => {
  const { startup } = data
  return (
    <Column style={{ gap: 12 }}>
      <BoldH5Text>{startup.name}</BoldH5Text>
      <LocationMemberInfo
        memberCount={startup.memberCount}
        devCount={startup.devCount}
        location={startup.location}
        serviceAreas={startup.serviceAreas}
      />
      <Hashtag data={startup.tags} />
      <SubDescText>{startup.briefIntro}</SubDescText>
    </Column>
  )
}

const BoldH5Text = styled(BoldText)`
  font-size: ${(p) => p.theme.fontSize.h5};
`

const SubDescText = styled(DescText)`
  color: ${(p) => p.theme.color.black2};
  line-height: 1.4;
`

export const StickyBox = styled(Column)`
  position: sticky;
  top: 80px;
  width: 280px;
  gap: 16px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    display: none;
  }
`

export const StartupSimpleBox = styled(OutlinedCard)`
  padding: 20px;
  cursor: pointer;
  :hover {
    border-color: ${(p) => p.theme.color.gray2};
  }
`

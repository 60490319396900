import styled from 'styled-components'
import { MediumText } from 'components/common/text'
import React from 'react'
import { StartupDetail, PartialStartupPosition } from 'types/common'
import { Column, Row } from 'components/common/layout'
import {
  useIsDesktop,
  useIsLoggedIn,
  usePositionIsInterestedUpdate,
} from 'utils/hooks'
import { useStores } from 'stores/globals'
import { useRouter } from 'next/router'
import { getDiffFromPast } from 'utils/formatters'
import { displayPositionName } from 'utils/displays'
import {
  Tag,
  TagContainer,
  Tags,
  TagSize,
  TagType,
} from 'components/common/tag'
import { NotFoundContent } from 'components/startup/startup-styles'
import { analytics } from 'infra/analytics'
import { Card } from 'components/common/card'
import { updateStartupPositionInterested } from 'utils/handlers'
import { InterestIcon } from 'components/misc/interest-icon'

export const StartupPositionCard: React.FC<{
  item: PartialStartupPosition
  startupId: number
}> = ({ item, startupId }) => {
  const isLoggedIn = useIsLoggedIn()
  const { loginStore } = useStores()
  const openLoginModal = () => loginStore.setIsModalOpen(true)
  const isDesktop = useIsDesktop()
  const { positionModalStore } = useStores()
  const router = useRouter()
  const isDashboard = router.pathname.includes('dashboard')
  usePositionIsInterestedUpdate([item.id], `/startups/${startupId}`)
  return (
    <PositionCard
      onClick={() => {
        isDesktop
          ? router.asPath.includes('startups') || isDashboard
            ? positionModalStore.openModal(item.id)
            : window.open(`/positions/${item.id}`, '_blank')
          : router.push(`/positions/${item.id}`)
      }}
    >
      <Column style={{ gap: 8 }}>
        <TagContainer style={{ marginRight: isDashboard ? 0 : 32 }}>
          <PositionName>
            {displayPositionName(
              item.name,
              item.careerType,
              item.experienceRange,
            )}
          </PositionName>
          {getDiffFromPast(item.publishedAt) < 8 && (
            <Tag variant={TagType.RED} size={TagSize.EXTRA_SMALL}>
              NEW
            </Tag>
          )}
        </TagContainer>
        {!isDashboard && (
          <div style={{ position: 'absolute', top: 8, right: 8 }}>
            <InterestIcon
              isInterested={item.isInterested}
              onClick={(e) => {
                e.stopPropagation()
                if (!isLoggedIn) return openLoginModal()
                updateStartupPositionInterested(item.id, !item.isInterested)
                analytics.track('compDetail_jsu_click_saveBtn_jobPost', {
                  startupId,
                  positionId: item.id,
                  positionName: item.name,
                })
              }}
            />
          </div>
        )}
        <Row>
          <TechStackTagContainer>
            <Tags maxLength={3} data={item.techStacks} />
          </TechStackTagContainer>
        </Row>
      </Column>
    </PositionCard>
  )
}

// 스타트업 포지션 컨테이너
export const StartupPositions: React.FC<{
  data: StartupDetail
}> = ({ data }) => {
  return (
    <Column>
      {data.positions.length > 0 ? (
        <PositionList>
          {data.positions.map((item) => (
            <div
              key={item.id}
              onClick={() =>
                analytics.track('compDetail_jsu_click_jobPost', {
                  startupId: data.id,
                  startupName: data.name,
                  positionId: item.id,
                  positionName: item.name,
                })
              }
            >
              <StartupPositionCard item={item} startupId={data.id} />
            </div>
          ))}
        </PositionList>
      ) : (
        <NotFoundContent>채용 준비중입니다.</NotFoundContent>
      )}
    </Column>
  )
}

const TechStackTagContainer = styled(TagContainer)`
  div {
    background-color: ${(p) => p.theme.color.white1};
  }
`

const PositionCard = styled(Card)`
  position: relative;
  gap: 10px;
  justify-content: space-between;
  cursor: pointer;
  min-height: 140px;
  height: 100%;
  border-radius: 8px;
  padding: 16px;
  background-color: ${(p) => p.theme.color.background2};
  :hover {
    background: ${(p) =>
      `linear-gradient(${p.theme.color.white1}52, ${p.theme.color.white1}52), ${p.theme.color.background2}`};
  }
`

const PositionName = styled(MediumText)`
  font-size: ${(p) => p.theme.fontSize.h5};
  line-height: 1.4;
`

const PositionList = styled.div`
  display: grid;
  gap: 12px;
  @media ${(p) => p.theme.deviceSize.desktop} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    grid-template-columns: 1fr;
  }
`

import React from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { BoldText, SemiBoldText } from 'components/common/text'
import { Card } from 'components/common/card'
import { Checker } from 'components/common/icon'
import { LineLimitedText } from 'components/scouts/profile-detail/desc-more-view'
import { theme } from 'infra/theme'
import {
  ApplicationFulfillment,
  ApplicationFulfillmentCondition,
} from 'types/common'
import { calculatePositionFulfillmentScore } from 'utils/formatters'
import { useIsDesktopCSR } from 'utils/hooks'
import { IS_BROWSER } from 'infra/constants'

export const PositionApplicationFulfillmentCondition: React.FC<{
  userName: string
  fulfillments: ApplicationFulfillment
}> = ({ userName, fulfillments }) => {
  const isDesktop = useIsDesktopCSR()
  const score = calculatePositionFulfillmentScore(fulfillments)
  const isActive = score >= 50
  const sortByIsFit = (items: ApplicationFulfillmentCondition[]) => {
    return [...items].sort((a, b) => {
      // b가 isFit이면 앞으로 오도록 (true=1, false=0) 비교
      return Number(b.isFit) - Number(a.isFit)
    })
  }
  const sortedTasks = sortByIsFit(fulfillments.task)
  const sortedQualifications = sortByIsFit(fulfillments.qualification)
  const sortedPreferred = sortByIsFit(fulfillments.preferred)

  return (
    <Column style={{ gap: 8, alignItems: 'center' }}>
      <TitleText>
        {userName}님이 생각하는
        {isDesktop ? ' ' : <br />}
        채용 공고 적합도는{' '}
        <span
          style={{ color: isActive ? theme.color.primary : theme.color.gray2 }}
        >
          {score}%
        </span>{' '}
        예요
      </TitleText>
      <ConditionCard isActive={isActive}>
        {fulfillments.task.length > 0 && (
          <>
            <ConditionSectionText>주요업무</ConditionSectionText>
            {sortedTasks.map((item) => (
              <FulfillmentItem item={item} key={item.conditionId} />
            ))}
          </>
        )}
        {fulfillments.qualification.length > 0 && (
          <>
            <ConditionSectionText>자격요건</ConditionSectionText>
            {sortedQualifications.map((item) => (
              <FulfillmentItem item={item} key={item.conditionId} />
            ))}
          </>
        )}
        {fulfillments.preferred.length > 0 && (
          <>
            <ConditionSectionText>우대사항</ConditionSectionText>
            {sortedPreferred.map((item) => (
              <FulfillmentItem item={item} key={item.conditionId} />
            ))}
          </>
        )}
      </ConditionCard>
    </Column>
  )
}

export const FulfillmentItem: React.FC<{
  item: ApplicationFulfillmentCondition
  themeColor?: 'white' | 'dark'
}> = ({ item, themeColor = 'white' }) => {
  const isDesktop = useIsDesktopCSR()
  return (
    <ItemContainer>
      <Column>
        <Checker isChecked={item.isFit} />
      </Column>
      <TextWrapper>
        <ConditionTitleText
          style={{
            color: themeColor === 'dark' ? theme.color.white1 : undefined,
          }}
        >
          {item.condition}
        </ConditionTitleText>
        <LineLimitedText
          lineClamp={1}
          color={
            themeColor === 'white' ? theme.color.black3 : theme.color.gray3
          }
          text={item.description || ''}
          fontSize={isDesktop ? 15 : 14}
        />
      </TextWrapper>
    </ItemContainer>
  )
}

const TitleText = styled(BoldText)`
  text-align: center;
  font-size: ${(p) => p.theme.fontSize.desc};
`

const ConditionCard = styled(Card)<{ isActive: boolean }>`
  gap: 16px;
  width: 100%;
  padding: 16px;
  border: 1px solid
    ${(p) => (p.isActive ? p.theme.color.primary : p.theme.color.line)};
  @media ${(p) => p.theme.deviceSize.mobile} {
    ${IS_BROWSER && // pdf 다운시 모바일 사이즈도 적용되서 스크롤이 생김
    `
    max-height: 300px;
    overflow: hidden scroll;
    justify-content: start;
    `}
  }
`

const ConditionSectionText = styled(SemiBoldText)`
  color: ${(p) => p.theme.color.gray2};
`

const ConditionTitleText = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.desc};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
  }
`

const ItemContainer = styled(Row)`
  gap: 8px;
  align-items: flex-start;
`

const TextWrapper = styled(Column)`
  gap: 8px;
`

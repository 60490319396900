import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { PositionApplicationStore } from 'stores/position-application'
import { Column, Row } from 'components/common/layout'
import {
  PositionApplicationCharCountText,
  PositionApplicationTempSavingTag,
  PositionApplicationTextArea,
  PositionApplicationTextAreaContainer,
  PositionApplicationFitStepSectionText,
} from 'containers/positions/application/styles'
import { useDebounce } from 'utils/hooks'
import { putStartupPositionApplicationTemp } from 'apis/request'
import { APPLICATION_MESSAGE_MAX_LENGTH } from 'infra/constants'

export const PositionApplicationFitReason: React.FC<{
  store: PositionApplicationStore
}> = observer(({ store }) => {
  const saveTempFitReason = useDebounce(1000, async (v: string) => {
    await putStartupPositionApplicationTemp(
      store.positionDetail?.id || 0,
      false,
      { fitReason: v },
    )
    store.setState('isSavingFitReason', false)
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => store.setState('curTab', 0), [])
  return (
    <Column style={{ gap: 16, height: '100%', padding: 10 }}>
      <PositionApplicationFitStepSectionText>
        그밖에 더 어필하고 싶은 내용이 있다면 작성해주세요
      </PositionApplicationFitStepSectionText>
      <PositionApplicationTextAreaContainer>
        <PositionApplicationTextArea
          style={{ height: 200 }}
          value={store.fitReason || ''}
          placeholder={`‧ 채용 조건을 만족하지 않지만 다른 강점으로 대신할 수 있는 부분이 있다면 어필해 보세요\n‧ ${store.startupDetail?.name}의 인재상에 부합하는 강점을 어필해 보세요`}
          maxLength={APPLICATION_MESSAGE_MAX_LENGTH}
          onChange={(e) => {
            if (e.target.value.length > APPLICATION_MESSAGE_MAX_LENGTH) return
            store.setState('isSavingFitReason', true)
            store.setState('fitReason', e.target.value)
            saveTempFitReason(e.target.value)
          }}
        />
        <Row style={{ height: 30 }}>
          {store.isSavingFitReason && (
            <PositionApplicationTempSavingTag>
              임시 저장 중
            </PositionApplicationTempSavingTag>
          )}
          <PositionApplicationCharCountText>
            {store.fitReason?.length || 0}/{APPLICATION_MESSAGE_MAX_LENGTH}
          </PositionApplicationCharCountText>
        </Row>
      </PositionApplicationTextAreaContainer>
    </Column>
  )
})

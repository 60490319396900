import React from 'react'
import styled from 'styled-components'
import { BsMagic } from 'react-icons/bs'
import { Column, Row } from 'components/common/layout'
import { SemiBoldText } from 'components/common/text'

export const ApplicationTipBtn: React.FC = () => {
  return (
    <Container
      onClick={() =>
        window.open(
          'https://mulberry-capacity-322.notion.site/197a20ad96d8800291c0f12da7575e48?pvs=4',
          '_blank',
        )
      }
    >
      <IconWrapper>
        <BsMagic color='#1F4534' />
      </IconWrapper>
      <MainText>
        서류
        <br />
        합격률
        <br />
        높이는
        <br />
        TIP
      </MainText>
    </Container>
  )
}

const Container = styled(Column)`
  z-index: 9999;
  width: 56px;
  gap: 10px;
  right: 25px;
  bottom: 130px;
  position: fixed;
  padding: 10px 10px 12px 10px;
  border-radius: 100px;
  background: ${(p) => p.theme.color.background3};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`

const MainText = styled(SemiBoldText)`
  text-align: center;
  color: ${(p) => p.theme.color.primary4};
  font-size: ${(p) => p.theme.fontSize.small};
  line-height: 1.5;
`

const IconWrapper = styled(Row)`
  height: 36px;
  width: 36px;
  padding: 10px;
  border-radius: 100px;
  background: rgba(36, 213, 158, 0.5);
`

import React from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import {
  StartupPositionRemoteWorkType,
  NumberOrNull,
  PositionType,
  Range,
  StartupPositionRemoteWorkEnum,
} from 'types/common'
import { BaseText, MediumText } from 'components/common/text'
import { formatExperienceRange } from 'utils/displays'
import {
  DASHBOARD_FILTER_EXPERIENCED_JOB_INTEREST_LEVELS,
  DEV_PARENT_POSITION_ID,
  STARTUP_POSITION_FORM_INTERN_JOB_INTEREST_LEVELS,
} from 'infra/constants'

export const PositionTable: React.FC<{
  data: {
    positionTypes: PositionType[]
    internPeriod: NumberOrNull
    jobInterestLevel: NumberOrNull
    careerType: string
    experienceRange: Range | null
    hasCto: boolean
    hasSupervisor: boolean
    remoteWorkPreference: StartupPositionRemoteWorkType
  }
}> = ({ data }) => {
  const optional = []
  if (
    data.hasCto &&
    data.positionTypes
      .map((item) => item.parentId)
      .includes(DEV_PARENT_POSITION_ID)
  )
    optional.push('CTO 있음')
  if (data.hasSupervisor) optional.push('사수 있음')
  if (data.remoteWorkPreference)
    optional.push(StartupPositionRemoteWorkEnum[data.remoteWorkPreference])

  const jobInterestLevels = [
    ...DASHBOARD_FILTER_EXPERIENCED_JOB_INTEREST_LEVELS,
    ...STARTUP_POSITION_FORM_INTERN_JOB_INTEREST_LEVELS,
  ]

  return (
    <Container>
      <Row>
        <MainText>포지션</MainText>
        <SubText>
          {data.positionTypes.map((item) => item.name).join(', ')}
        </SubText>
      </Row>
      <Row>
        <MainText>경력 구분</MainText>
        <SubText>
          {data.careerType}
          {data.careerType === '경력' && (
            <>
              {' '}
              {formatExperienceRange(
                data.experienceRange?.min || 0,
                data.experienceRange?.max || 10,
              )}
            </>
          )}
          {data.jobInterestLevel && (
            <b>
              {' '}
              (
              {
                jobInterestLevels.find(
                  (item) => item.id === data.jobInterestLevel,
                )?.name
              }
              )
            </b>
          )}
        </SubText>
      </Row>
      {data.internPeriod && (
        <Row>
          <MainText>기간</MainText>
          <SubText>{data.internPeriod}개월</SubText>
        </Row>
      )}
      <Row>
        <MainText>특이사항</MainText>
        <SubText>{optional.length > 0 ? optional.join(', ') : '-'}</SubText>
      </Row>
    </Container>
  )
}

const Container = styled(Column)`
  gap: 16px;
  padding: 12px;
  border-top: 1px solid ${(p) => p.theme.color.line};
  border-bottom: 1px solid ${(p) => p.theme.color.line};
`

const MainText = styled(MediumText)`
  flex: none;
  width: 100px;
  color: ${(p) => p.theme.color.gray2};
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.desc};
  }
`

const SubText = styled(BaseText)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.desc};
  }
`

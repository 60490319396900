import React from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { FiHeart } from 'react-icons/fi'
import { theme } from 'infra/theme'

export const InterestIcon: React.FC<{
  size?: number
  isInterested?: boolean
  onClick?: (e: React.MouseEvent) => void
}> = ({ isInterested, onClick, size = 20 }) => {
  return (
    <IconWrapper onClick={onClick}>
      <FiHeart
        color={isInterested ? theme.color.primary : theme.color.gray3}
        fill={isInterested ? theme.color.primary : theme.color.white1}
        size={size}
      />
    </IconWrapper>
  )
}

const IconWrapper = styled(Row)`
  flex: none;
  padding: 6px;
  justify-content: center;
  border-radius: 100px;
  transition: 0.2s;
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.color.background};
  }
`

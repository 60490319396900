import React, { useState } from 'react'
import {
  StartupPositionDetail,
  StartupPositionForSearch,
  StartupPositionForSearchNoRequirePosition,
  UserApplicationForPosition,
} from 'types/common'
import { observer } from 'mobx-react'
import { useIsDesktop, useIsLoggedIn } from 'utils/hooks'
import { useStores } from 'stores/globals'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { useScoutMyRequest, useUser } from 'apis/hooks'
import { BtnSize, BtnType, Button } from 'components/common/button'
import { ResponsiveDisplay } from 'components/common/layout'
import Tippy from '@tippyjs/react'
import { TooltipContent } from 'components/common/tooltip'
import { isEndWithConsonant } from 'utils/validations'
import { REAPPLY_ALERT } from 'infra/constants'
import moment from 'moment'
import { useRouter } from 'next/router'
import TagManager from 'react-gtm-module'
import { FiHeart } from 'react-icons/fi'
import { theme } from 'infra/theme'

const getApplicationStatusDesc = (
  userApplication: UserApplicationForPosition | null,
  userName: string,
  startupName: string,
) => {
  return userApplication?.applyAvailableDate
    ? {
        desktop: `지원 가능 횟수를 초과하여 ${moment(
          userApplication.applyAvailableDate,
        ).format('M월 D일')}까지 해당 공고에 지원할 수 없어요.`,
        mobile: '지원 가능 횟수 초과',
      }
    : userApplication?.lastApplication?.acceptedAt
    ? {
        desktop: '최종 합격 되었어요. 취업 축하금을 신청해보세요 :)',
        mobile: '최종 합격',
      }
    : userApplication?.lastApplication?.resumeAcceptedAt
    ? {
        desktop: `서류 합격 되었어요. 채팅으로 ${startupName}${
          isEndWithConsonant(startupName) ? '과' : '와'
        } 채용 절차를 진행해보세요!`,
        mobile: '서류 합격',
      }
    : {
        desktop: `이미 지원하여, ${startupName}${
          isEndWithConsonant(startupName) ? '이' : '가'
        } ${userName}님을 검토하고 있어요.`,
        mobile: '결과 대기중',
      }
}

export const PositionButton: React.FC<{
  data:
    | StartupPositionDetail
    | StartupPositionForSearch
    | StartupPositionForSearchNoRequirePosition // userApplication가 없을 수 있는 타입이나, 있는 경우에만 PositionButton 컴포넌트 렌더됨
  openDeactivatedModal: () => void
  onSaveClick?: (bool: boolean) => void
  onApplyClick?: () => void
}> = observer(({ data, openDeactivatedModal, onSaveClick, onApplyClick }) => {
  const { data: user } = useUser()
  const { data: profile } = useScoutMyRequest()
  const isLoggedIn = useIsLoggedIn()
  const isDesktop = useIsDesktop()
  const router = useRouter()
  const [isInterested, setIsInterested] = useState(data.isInterested)
  const { loginStore, applyModalStore } = useStores()
  const openLoginModal = () => loginStore.setIsModalOpen(true)
  const clickSaveBtn = () => {
    if (!isLoggedIn) return openLoginModal()
    onSaveClick?.(!!isInterested)
    setIsInterested((prev) => !prev)
    if (!isInterested)
      TagManager.dataLayer({
        dataLayer: {
          event: 'btn_click_saveinterest',
          startup: data.startup.name,
        },
      })
  }
  const isApplyDisabled = !data.userApplication?.isPossibleApply // 포지션이 존재하여 PositionButton 컴포넌트가 렌더될 경우 항상 userApplication값 있을 예정
  const applicationStatusDesc = getApplicationStatusDesc(
    data.userApplication,
    user?.name || '',
    data.startup.name,
  )
  return (
    <BtnContainer>
      <ResponsiveDisplay isDisableDesktop>
        <MobileSaveBtn variant={BtnType.OUTLINE} onClick={clickSaveBtn}>
          <Center>
            <div style={{ width: 20 }}>
              <FiHeart
                size={20}
                color={isInterested ? theme.color.primary : theme.color.gray1}
                fill={isInterested ? theme.color.primary : theme.color.white1}
              />
            </div>
          </Center>
        </MobileSaveBtn>
      </ResponsiveDisplay>
      <ResponsiveDisplay isDisableMobile>
        <BaseBtn
          style={{ width: 100 }}
          variant={!isInterested ? BtnType.PRIMARY2 : BtnType.OUTLINE}
          onClick={clickSaveBtn}
        >
          <Center style={{ gap: 6 }}>
            <FiHeart
              size={16}
              color={isInterested ? theme.color.primary : theme.color.primary4}
              fill={isInterested ? theme.color.primary : theme.color.primary3}
            />
            <span>{isInterested ? '해제' : '관심'}</span>
          </Center>
        </BaseBtn>
      </ResponsiveDisplay>
      <Tippy
        content={
          isApplyDisabled ? (
            <TooltipContent>
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {applicationStatusDesc.desktop}
              </span>
            </TooltipContent>
          ) : undefined
        }
      >
        <ApplyBtn
          isApplyDisabled={isApplyDisabled}
          variant={
            data.userApplication?.hasTempApplication
              ? BtnType.PRIMARY3
              : isApplyDisabled
              ? BtnType.DISABLE
              : BtnType.PRIMARY
          }
          onClick={() => {
            if (isApplyDisabled) return
            if (!isLoggedIn) return openLoginModal()
            if (user && !user.isActive) return openDeactivatedModal()
            if (
              data.userApplication?.lastApplication &&
              !confirm(REAPPLY_ALERT)
            )
              return
            if (
              !profile?.educations.length ||
              (!profile.careers.length && !profile.otherActivities.length)
            )
              return applyModalStore.setIsProfileNotFilledModalOpen(true)
            onApplyClick?.()
            if (isDesktop) {
              applyModalStore.openModal(data.id || 0, data.startup.id)
            } else {
              router.push(
                `/positions/application?positionId=${data.id}&startupId=${data.startup.id}`,
              )
            }
            TagManager.dataLayer({
              dataLayer: {
                event: data.userApplication?.hasTempApplication
                  ? 'btn_click_continue_apply'
                  : 'btn_click_apply',
                startup: data.startup.name,
              },
            })
          }}
        >
          <Column>
            {data.userApplication?.hasTempApplication
              ? '이어서 지원하기'
              : isApplyDisabled
              ? '지원 완료'
              : data.userApplication?.lastApplication
              ? '다시 지원하기'
              : '지원하기'}
            {isApplyDisabled && (
              <MobileText>({applicationStatusDesc.mobile})</MobileText>
            )}
          </Column>
        </ApplyBtn>
      </Tippy>
    </BtnContainer>
  )
})

const BtnContainer = styled(Row)`
  gap: 8px;
  width: 100%;
`

const Center = styled(Row)`
  justify-content: center;
`

const BaseBtn = styled(Button).attrs({ size: BtnSize.MEDIUM })``

const ApplyBtn = styled(BaseBtn)<{ isApplyDisabled: boolean }>`
  position: relative;
  @media ${(p) => p.theme.deviceSize.mobile} {
    ${(p) => p.isApplyDisabled && 'padding: 0; height: 44px;'}
  }
`

const MobileSaveBtn = styled(BaseBtn)`
  border-color: ${(p) => p.theme.color.gray2};
  :hover {
    background: none;
  }
`

const MobileText = styled.span`
  font-size: 10px;
  @media ${(p) => p.theme.deviceSize.desktop} {
    display: none;
  }
`
